'use strict';

define('vb/private/vx/extensionAdapterFactory',['vb/private/utils',
], (Utils) => {
  /**
   * Factory class for retrieving the class name for the extension adapter
   */
  class ExtensionAdapterFactory {
    /**
     * @param {String} version the extension manager version
     * @param {Object} vbInitConfig
     * returns the Adapter resource used for working with the Extension Registry
     */
    static get(version = 'v1', vbInitConfig = globalThis.vbInitConfig || {}) {
      let url;
      // Depending on the format of the URL, use different adapter class
      let adapterName = 'baseExtensionAdapter';

      if (version === 'v2') {
        // Preference is given to the DIGEST_ENDPOINT property set when using SUIS
        url = Utils.cleanString(vbInitConfig.DIGEST_ENDPOINT);
      }

      if (url) {
        adapterName = 'endpointExtensionAdapter';
      } else {
        url = Utils.cleanString(vbInitConfig.REGISTRY_URL);

        if (url) {
          if (url.indexOf('http') === 0) {
            // TODO implement a PWA adaptor by checking PwaUtils.isPwaConfig(globalThis.vbInitConfig)
            adapterName = 'remoteExtensionAdapter';

            // Fix the registry URL for older FA application
            // Older FA application set REGISTRY_URL in vbInitConfig to:
            //   https://<fa-server>/fscmUI/vxmanifest/v1/applications/
            // In order to work with extension manager v2, the URL should only be the base:
            //   https://<fa-server>/fscmUI/

            // Older applications have the registry URL as https://<fa-server>/fscmUI/vxmanifest/v1/applications/
            // and we want https://<fa-server>/fscmUI/
            const manifestIndex = url.indexOf('vxmanifest');
            if (manifestIndex > 0) {
              url = url.substring(0, manifestIndex);
            }

            if (version === 'v2') {
              url = `${Utils.addTrailingSlash(url)}vxmanifest/v2/digests/`;
            }
          } else {
            adapterName = 'localExtensionAdapter';
          }
        } else {
          // If no registry URL is defined, the app does not have an extension manager
          return Promise.resolve();
        }
      }

      return Utils.getResource(`vb/private/vx/${version}/${adapterName}`)
        .then((Adapter) => new Adapter(url));
    }
  }

  return ExtensionAdapterFactory;
});

