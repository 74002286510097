/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/helpers/extensionRestHelperUtils',[
  'vb/private/log',
  'vb/private/services/serviceResolver',
], (
  Log,
  ServiceResolver,
) => {
  const logger = Log.getLogger('/vb/private/helpers/extensionRestHelperUtils');

  class ExtensionRestHelperUtils {
    /**
     * Defines new module which will be used as a public RestHelper for the given extension.
     * It provides built-in scope which is used for correctly resolving endpoint references for that extension.
     *
     * @param {string} extId
     * @returns {string} The generated module path that should be mapped to 'vb/private/rest' within the extension
     */
    static defineExtensionRestHelper(extId) {
      const extRestHelperPath = `vx/${extId}/ext/RestHelper`;

      define(extRestHelperPath, ['vb/private/helpers/rest'], (RestHelper) => {
        class ExtensionRestHelper extends RestHelper {
          /**
           * "Override" RestHelper.get() to:
           * 1. provide default context for resolving endpoint reference
           * 2. validate that provided context is correct
           * @param {string} endpointId
           * @param {Object} context
           * @returns {RestHelper}
           */
          static get(endpointId, context) {
            // we validate that extension Id is correct for our extension
            // do not use strict validation while getting the namespace, since we know what namespace we expect here
            if (context && ServiceResolver.getNamespace(context) !== extId) {
              logger.warn('Invalid context for the Rest.get():"', context.extensionId, '". Expected "', extId, '".');
            }
            let extContext = context;
            if (!extContext) {
              extContext = { extensionId: extId };
            }

            // base class constructor will create an EndpointReference from this
            return RestHelper.get(endpointId, extContext);
          }
        }
        return ExtensionRestHelper;
      });

      return extRestHelperPath;
    }
  }
  return ExtensionRestHelperUtils;
});

