/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/action/actionRunner',['vb/action/action', 'vb/helpers/actionHelpers', 'vb/private/action/actionChainUtils',
  'vb/private/utils', 'vb/private/constants', 'vbc/private/monitorOptions'],
(Action, ActionHelpers, ActionChainUtils, Utils, Constants, MonitorOptions) => {
  const logger = ActionChainUtils.getLogger();

  class ActionMonitorOptions extends MonitorOptions {
    constructor(actionId, module, action) {
      const message = `${module} ${action.logLabel}`;
      super('action', message);
      this.addTags(() => ({
        actionId,
        actionType: module,
      }));
    }
  }

  class ActionError extends Error {
    constructor(message, payload) {
      super(message);
      this.payload = payload;
    }
  }

  /**
   * This class contains methods for running an action.
   */
  class ActionRunner {
    /**
     * Invokes the action identified by actionModuleId. Note that method is only called from a JS chain.
     *
     * @param actionModuleId the id for the action module
     * @param executionContext execution context containing all the information necessary to invoke the action
     * @param params action parameters
     * @param options
     * @returns {Promise}
     */
    static run(actionModuleId, executionContext, params, options = {}) {
      return this.loadActionModule(actionModuleId, executionContext)
        .then((NewAction) => {
          const internalContext = ActionChainUtils.getInternalContext(executionContext);
          const { chainId } = internalContext;
          const action = this.createAction(NewAction, actionModuleId, executionContext, options);
          const debugStream = ActionChainUtils.getDebugStream(executionContext);

          logger.info('Chain', chainId, 'starting action', action.logLabel,
            'with parameters:', params);

          // When the current page is exited due to navigation, all the pending actions on that page
          // will get cancelled. Executing an action in such a scenario will result in errors.
          // Instead, we will log a warning and return undefined.
          if (ActionChainUtils.isContainerInactive(action.containerLifecycleState)) {
            logger.warn('Chain', chainId, 'action', action.logLabel,
              'cannot be executed because the associated page or fragment state has been disposed. ' +
              'This can occur as a result of a page navigation.');
            // Return the default value of result in an action outcome.
            return undefined;
          }

          return this.monitorAction(action, actionModuleId, chainId, params,
            (totalStepTime) => debugStream.actionStart(action, params)
              .then(() => action.start(params))
              .then((outcome) => {
                debugStream.actionEnd(action, outcome);

                const result = this.handleActionOutcome(actionModuleId, outcome);

                logger.info('Chain', chainId, 'ending action', action.logLabel,
                  'with result', result, totalStepTime());

                return result;
              })
              .catch((e) => {
                logger.error('Chain', chainId, 'action step', action.logLabel, 'failed.', e,
                  totalStepTime(e));
                throw e;
              }));
        });
    }

    /**
     * Creates and runs a new instance of NewAction synchronously. This method will only work
     * for actions whose perform method does not return a promise, e.g., assignVariablesAction.
     *
     * @param NewAction the loaded action module
     * @param actionModuleId the id for the action module
     * @param executionContext execution context containing all the information necessary to invoke the action
     * @param params action parameters
     * @param options
     * @returns {*}
     */
    static runSync(NewAction, actionModuleId, executionContext, params, options = {}) {
      const internalContext = ActionChainUtils.getInternalContext(executionContext);
      const { chainId } = internalContext;
      const action = this.createAction(NewAction, actionModuleId, executionContext, options);
      const debugStream = ActionChainUtils.getDebugStream(executionContext);

      logger.info('Chain', chainId, 'starting action', action.logLabel,
        'with parameters:', params);

      // Actions will get cancelled when the underlying container is exited
      if (ActionChainUtils.isContainerInactive(action.containerLifecycleState)) {
        logger.warn('Chain', chainId, 'action', action.logLabel,
          'cannot be executed because the associated page or fragment state has been disposed. ' +
          'This can occur as a result of a page navigation.');
        return undefined;
      }

      return this.monitorAction(action, actionModuleId, chainId, params,
        (totalStepTime) => {
          try {
            debugStream.actionStart(action, params);

            // directly call perform which should not return a promise for this to work
            const outcome = action.perform(params);

            debugStream.actionEnd(action, outcome);

            const result = this.handleActionOutcome(actionModuleId, outcome);

            logger.info('Chain', chainId, 'ending action', action.logLabel,
              'with result', result, totalStepTime());

            return result;
          } catch (e) {
            logger.error('Chain', chainId, 'action step', action.logLabel, 'failed.', e,
              totalStepTime(e));
            throw e;
          }
        });
    }

    /**
     * Creates an instance of NewAction and readies it for execution.
     *
     * @param NewAction the loaded action module
     * @param actionModuleId the id for the action module
     * @param executionContext execution context containing all the information necessary to invoke the action
     * @param options
     * @returns {*}
     */
    static createAction(NewAction, actionModuleId, executionContext, options) {
      const id = options.id || options.alias;
      const internalContext = ActionChainUtils.getInternalContext(executionContext);

      // create and configure the action
      const actionConfig = this.getActionConfig(id, internalContext);
      const action = new NewAction(id, id, actionConfig);

      this.addOptionsToAction(actionModuleId, action);

      this.addHelpersToAction(action, internalContext);

      this.addContextToAction(actionModuleId, action, executionContext);

      return action;
    }

    /**
     * Runs telemetry monitoring for the action.
     *
     * @param action action to monitor
     * @param actionModuleId the id for the action module
     * @param chainId chain id
     * @param params action parameters
     * @param callback callback to be invoked by the monitor
     * @returns
     */
    static monitorAction(action, actionModuleId, chainId, params, callback) {
      const mo = new ActionMonitorOptions(action.id, actionModuleId, action);

      return logger.monitor(mo, callback);
    }

    /**
     * Unwraps an action outcome. For a success outcome, the corresponding result is returned. For a failure
     * outcome, information is extracted from the outcome and an instance of ActionError is thrown.
     *
     * @param actionModuleId the id for the action module
     * @param outcome the action outcome to unwrap
     * @returns {*}
     */
    static handleActionOutcome(actionModuleId, outcome) {
      if (outcome.name === Action.Outcome.SUCCESS) {
        return outcome.result;
      }

      const { result } = outcome;
      const { message, error, payload } = result;

      throw error || new ActionError(message.summary, payload);
    }

    /**
     * Load the action module specified by actionModuleId. This method will return the cached module if it
     * has already been loaded.
     *
     * @param actionModuleId the id for the action module to load
     */
    static loadActionModule(actionModuleId, executionContext) {
      this.actionModules = this.actionModules || {};

      return Promise.resolve().then(() => {
        const module = this.actionModules[actionModuleId];

        if (!module) {
          let actionModulePath = actionModuleId;

          // need to adjust the module path for a custom action defined in a V2 extension
          if (!actionModuleId.startsWith(Constants.BUILTIN_ACTION_PATH_PREFIX) && executionContext) {
            const internalContext = ActionChainUtils.getInternalContext(executionContext);

            // make sure we don't break unit tests
            if (internalContext && internalContext.targetContainer
              && typeof internalContext.targetContainer.adjustImportPath === 'function') {
              actionModulePath = internalContext.targetContainer.adjustImportPath(actionModuleId);
            }
          }

          return Utils.getResource(actionModulePath)
            // TODO: turn off caching for now to unblock preflight
            // this.actionModules[actionModuleId] = actionModule;
            .then((actionModule) => actionModule);
        }

        return module;
      });
    }

    /**
     * Add to an action the helper functions that require the context . The helper
     * functions are needed to implement user-specified actions.
     *
     * @param action
     * @param context
     */
    static addHelpersToAction(action, context) {
      // inject the helpers on the action
      Object.defineProperty(action, 'helpers', {
        value: new ActionHelpers(context),
      });
    }

    /**
     * Certain actions need more context. Instead of exposing an interface API, we instead look
     * for specific actions and inject the context.
     *
     * The advantage of this approach is that user-specified actions cannot configure their
     * actions to gain access to the context - something we want to avoid in general.
     *
     * @param actionType The module name of the action
     * @param action The newly created action
     * @param executionContext context necessary to invoke the action
     */
    static addContextToAction(actionType, action, executionContext) {
      const internalContext = ActionChainUtils.getInternalContext(executionContext);
      const writableContext = ActionChainUtils.getWritableExecutionContext(executionContext);

      if (actionType.startsWith(Constants.BUILTIN_ACTION_PATH_PREFIX)) {
        switch (actionType.substr(Constants.BUILTIN_ACTION_PATH_PREFIX.length)) {
          case 'assignVariablesAction':
          case 'resetVariablesAction': {
            action.setAvailableContext(writableContext);
            break;
          }

          case 'callChainAction': {
            const availableContextsClone = writableContext.clone();
            action.setContext(availableContextsClone, internalContext);
            break;
          }

          case 'restAction':
          case 'fireNotificationEventAction':
          case 'callComponentMethodAction':
          case 'loginAction':
          case 'logoutAction':
          case 'editorUrlAction':
          case 'getDirtyDataStatusAction':
          case 'resetDirtyDataStatusAction': {
            action.setContext(internalContext);
            break;
          }

          case 'fireCustomEventAction': {
            action.setInternalContext(internalContext, internalContext.internalContext);
            break;
          }

          default:
            break;
        }
      }

      // inject the container lifecycle state without making the container available to the action
      if (internalContext && internalContext.container) {
        Object.defineProperty(action, 'containerLifecycleState', {
          get: () => internalContext.container.lifecycleState,
        });
      }
    }

    /**
     * For JS chains, certain actions have different behaviors as compared to JSON chains. This method
     * sets options on an action to alter its behavior. For example, RestAction in a JS chain should
     * mirror the behavior of the underlying browser fetch. Instead of mapping a non-ok response
     * to a failure outcome, it should simply return the response and let the chain handle the response
     * status. Only errors from browser fetch should be thrown from the RestAction.
     *
     * @param actionType The module name of the action
     * @param action The newly created action
     */
    static addOptionsToAction(actionType, action) {
      if (actionType.startsWith(Constants.BUILTIN_ACTION_PATH_PREFIX)) {
        switch (actionType.substr(Constants.BUILTIN_ACTION_PATH_PREFIX.length)) {
          case 'restAction':
          case 'barcodeAction': {
            action.setOptions({
              mirrorBrowserApiBehavior: true,
            });
            break;
          }
          default:
            break;
        }
      }
    }

    /**
     * Get the configuration object to pass to the constructor. Currently only contains the 'registrar',
     * to allow action to register callbacks
     *
     * @param actionId
     * @param executionContext
     * @returns {Object}
     */
    static getActionConfig(actionId, executionContext) {
      // provide a narrow interface for the action to call
      return {
        registrar: {
          setFinallyCallback: (name, callback) => {
            this.setFinallyCallback(executionContext, actionId, name, callback);
          },
        },
      };
    }

    /**
     * Actions that are granted access to the actionChain can register a callback called when the chain is complete.
     * use an array; callbacks will be called in registration order
     * the callback can optionally return a Promise.
     *
     * @param actionInstanceId
     * @param name
     * @param callbackFnc
     */
    static setFinallyCallback(executionContext, actionInstanceId, name, callbackFnc) {
      const internalContext = ActionChainUtils.getInternalContext(executionContext);
      const { finallyCallbacks } = internalContext;
      const existing = finallyCallbacks.find((callback) => callback.id === actionInstanceId);

      // replace the existing one - each action only gets one
      if (existing) {
        existing.name = name;
        existing.fnc = callbackFnc;
      } else {
        finallyCallbacks.push({ id: actionInstanceId, name, fnc: callbackFnc });
      }
    }
  }

  return ActionRunner;
});

