/* eslint-disable max-classes-per-file */

'use strict';

/**
 * Definition of a path to a translation bundle
 * {
 *   path: "<bundlePath>"
 * }
 *
 * @typedef {Object} TranslationConfig
 * @property {string} path Path to the translation bundle
 */

/**
 * All the TranslationConfigs for an extension
 * {
 *   <bundleName>: {
 *     path: "<bundlePath>"
 *   }
 * }
 *
 * @typedef {Object} TranslationsConfig
 * @property {Object.<string, TranslationConfig>} translations map of bundle name to TranslationConfig
 */

/**
 * Extension/TranlationsConfig object
 * {
 *   extension: <Extension>,
 *   translations: {
 *     <bundleName>: {
 *       path: "<bundlePath>"
 *     }
 *   }
 * }
 *
 * @typedef {Object} ExtensionTranslationsConfig
 * @property {Object} extension The extension object that defines the translations
 * @property {TranslationsConfig} translations Translations for the extension
 * @memberof BundleUtils
 */

/**
 * Translation Bundles
 *
 * @typedef {Object} BundlesModel
 * @property {function} getStringMap
 * @property {function} getBundleDefinition
 * @property {function} getV2StringMap
 * @property {Object} getExtensionsV2StringMaps
 */

define('vb/private/translations/bundleUtils',[
  'ojs/ojcontext',
  'vb/private/constants',
  'vb/binding/expression',
], (
  ojContext,
  Constants,
  Expression,
) => {
  /**
   * we use the convention that V2 files are named "*-i18n".
   * requireJS requires the .js when the path includes protocol, and the file is not in a bundle.
   * Otherwise, the JS must be omitted.
   */
  const REGEXP_V2 = /-i18n(\.js)?$/;

  const TRANSLATION_BUNDLE_BUSYSTATE_PREFIX = 'Load translation bundle ';

  const trackedBundles = new Map();

  class BundleLoadTracker {
    constructor(bundleName, loadPromise) {
      this.bundleName = bundleName;
      this.loadPromise = loadPromise;
      // unique id generator lifted from BusyState
      this.id = `${globalThis.performance.now().toString(36)}_${Math.random().toString(36)}`;
    }
  }

  class BundleUtils {
    /**
     * path (file name) is used to determine which bundle version we have ('*-i18n')
     * @param {string} path
     * @returns {boolean}
     */
    static isV2(path) {
      return path && REGEXP_V2.test(path);
    }

    /**
     * Evaluate the translation definition's path, using initParams passed in the BundlesModel's options
     * @param {Object} declaration Translation declaration
     * @param {string} declaration.path Path to translation bundle;
     * @param {Object} [initParams] BundleModel's options.initParams
     * @returns {string}
     */
    static evaluateTranslationPath(declaration, initParams = {}) {
      return declaration && declaration.path && Expression
        .getEvaluatedSafe(declaration.path, {
          [Constants.ContextName.INIT_PARAMS]: initParams,
        });
    }

    /**
     * Add a Bundle Load tracker, because callers may not block on load().
     * This allows clients to know when bundles have finished loading.
     * @param {string} bundleName name of bundle
     * @returns {function():void} resolve function called by the registrant when the load completes.
     */
    static trackBundleLoad(bundleName, loadPromise) {
      const busyContext = ojContext.getPageContext().getBusyContext();
      // eslint-disable-next-line max-len
      const busyStateResolver = busyContext.addBusyState({ description: `${TRANSLATION_BUNDLE_BUSYSTATE_PREFIX} ${bundleName}` });

      const tracker = new BundleLoadTracker(bundleName, loadPromise);
      trackedBundles.set(tracker.id, tracker);
      return () => {
        busyStateResolver();
        trackedBundles.delete(tracker.id);
      };
    }

    /**
     * Wait for all Load translation bundle Busy States to be cleared.
     * N.B. In some unit tests, the 'VB Router' Busy State is never cleared so we can't always use
     *  await oj.Context.getPageContext().getBusyContext().whenReady();
     * @returns Promise
     */
    static async whenBundlesReady() {
      // Convert the trackedBundles Map into an array of loadPromises
      return Promise.all(Array.from(trackedBundles.values(), (tracker) => tracker.loadPromise));
    }
  }

  return BundleUtils;
});

