'use strict';

define('vb/private/vx/v2/localExtensionAdapter',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/private/vx/baseExtensionAdapter',
], (
  Constants,
  Utils,
  BaseExtensionAdapter,
) => {
  /**
   * The extension adapter used to load extension from local file
   */
  class LocalExtensionAdapter extends BaseExtensionAdapter {
    /**
     * For the local extension adapter, this function consist of loading the .json files mocking
     * the extension manager registry.
     * @return {Promise}
     */
    initiateFetchManifest() {
      return Promise.resolve()
        .then(() => this.loadDigest(['runtime', 'requirejs-info', 'app-ui-info']))
        .then((manifest) => {
          this.log.info('Found', manifest.extensions.length, 'extension(s).');
          return manifest;
        });
    }

    /**
     * Load the specified types from the remote extension digest
     * @param {String[]} types
     * @returns {Promise<object>}
     */
    loadDigest(types) {
      this.log.info('Loading extensions from local file', this.registryUrl,
        'using extension manager v2.');

      return Utils.loadAndParse(this.registryUrl)
        .then((registry) => {
          const reg = registry.extensions || [];
          const ref = {};
          const promises = [];

          // Reference the ui part and build the promises
          const isUnitTest = globalThis.vbInitConfig
            && globalThis.vbInitConfig.TEST_MODE === Constants.TestMode.UNIT_TEST;
          reg.forEach((ext) => {
            ref[ext.id] = ext;
            if (isUnitTest) {
              // in unit tests if available use 'baseUrl-unitTest' for loading extensions
              promises.push(Utils.loadAndParse(`${ext['baseUrl-unitTest'] || ext.baseUrl}/manifest.json`));
            } else {
              promises.push(Utils.loadAndParse(`${ext.baseUrl}/manifest.json`));
            }
          });

          // Load all the vb-extension.json
          return Promise.all(promises).then((extensions) => {
            const manifest = {};
            if (types.includes('runtime')) {
              // Extension Manager v2 returns digest.runtime[], so convert to v1 manifest.extensions[]
              manifest.extensions = [];
            }
            if (types.includes('app-ui-info')) {
              manifest.appUiInfo = [];
            }
            if (types.includes('requirejs-info')) {
              manifest.requirejsInfo = [];
            }
            if (types.includes('pwa-info')) {
              manifest.pwaInfo = [];
            }

            extensions.forEach((ext) => {
              if (manifest.extensions) {
                /* eslint-disable no-param-reassign */
                // Insert the baseUrl part in the extension
                // in unit tests if available use 'baseUrl-unitTest' for loading extensions
                ext.baseUrl = (isUnitTest && ref[ext.id]['baseUrl-unitTest']) || ref[ext.id].baseUrl;

                // Convert the dependencies
                if (ext.dependencies) {
                  const dependencies = ext.dependencies;
                  ext.dependencies = {};
                  dependencies.forEach((dep) => {
                    ext.dependencies[dep.id] = dep.version;
                  });
                }
                /* eslint-enable no-param-reassign */

                manifest.extensions.push(ext);
              }

              if (manifest.appUiInfo && ext['app-ui-info']) {
                manifest.appUiInfo.push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext['app-ui-info'].metadata,
                });
              }

              if (manifest.requirejsInfo && ext['requirejs-info']) {
                manifest.requirejsInfo.push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext['requirejs-info'].metadata,
                });
              }

              if (manifest.pwaInfo && ext['pwa-info']) {
                manifest.pwaInfo.push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext['pwa-info'].metadata,
                });
              }
            });

            return manifest;
          });
        });
    }
  }

  return LocalExtensionAdapter;
});

