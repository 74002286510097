'use strict';

/**
 * note: this has a dependency on Router; dependencies on classes that are dependant on JET can
 * cause circular requireJS dependencies, if not used carefully!!
 *
 */

define('vb/private/model/modelUtils',[
  'vb/private/helpers/eventHelper',
  'vb/private/stateManagement/router',
  'vb/private/log',
], (EventHelper, Router, Log) => {
  const logger = Log.getLogger('/vb/private/models/ModelUtils');

  class ModelUtils {
    /**
     * if a JS module has a constructor, the constructor is called, and passed an object that contains accessors
     * for helpers.
     *
     * Currently, the only accessor is getEventHelper() => EventHelper.
     *
     * @param Module if falsy, returns null
     * @param container if undefined, the context should still contain valid accessors for 'helpers', but the helpers
     *   should handle the lack of a container (throw exception, log error, etc.).
     *  If null, there will be no context, but that should not happen.
     * @returns {Object|null}
     */
    static initializeJsModule(Module, container) {
      let newModule = null;
      if (Module) {
        newModule = Module;
        if (typeof newModule === 'function') {
          // use the 'current' page for the context when constructing the metadata provider
          const cont = (container !== undefined) ? container : Router.getCurrentPage();

          if (!cont) {
            logger.warn('No container context for initializing module helpers');
          }

          const moduleContext = this.createModuleContext(cont);

          newModule = new Module(moduleContext);
        }
      }

      return newModule;
    }

    /**
     * passed to the module constructor; has an accessor to the EventHelper
     * The container is assumed to be valid; if somehow, if it is not, the helper should still be
     * accessible by the JS, but it should fail will logging, minimally.
     *
     * @param container can be null/undefined; EventHelper will throw errors when used if not defined.
     * @returns {{getEventHelper: (function(): *)}}
     * @private
     */
    static createModuleContext(container) {
      return {
        getEventHelper() {
          const eventHelper = new EventHelper({ container });
          // Add a way to dispose of the EventHelper when the container is deleted.
          container.unregisterEventArray.push(() => {
            eventHelper.dispose();
          });
          return eventHelper;
        },

      };
    }
  }

  return ModelUtils;
});

