/* eslint-disable no-param-reassign */

'use strict';

define('vb/private/vx/v2/extensionRegistry',[
  'vb/private/vx/baseExtensionRegistry',
  'vb/private/vx/v2/extension',
  'vb/private/configLoader',
  'compare-versions',
  'vb/private/constants',
  'vb/private/utils',
], (BaseExtensionRegistry, ExtensionV2, ConfigLoader, compareVersions, Constants, Utils) => {
  /**
   * The regex used to find and extract the APP UIs id in an extension
   * App UIs are always located in a self/applications folder and the descriptor
   * is app.json.
   * @type {RegExp}
   */
  const appPackageRegex = new RegExp(`^${Constants.DefaultPaths.UI}${Constants.ExtensionFolders.SELF}\
/${Constants.DefaultPaths.APPLICATIONS}(.*)/app.json$`);

  // const serviceRegex = /^services\/self\/([\w_$-]*)\/openapi[\w.]*\.json$/;
  const serviceRegex = new RegExp(`^${Constants.DefaultPaths.SERVICES}${Constants.ExtensionFolders.SELF}\
/([\\w_$-]*)/openapi[\\w.]*\\.json$`);
  // const catalogRegex = /^services\/self\/catalog.json$/;
  const catalogRegex = new RegExp(`^${Constants.DefaultPaths.SERVICES}${Constants.ExtensionFolders.SELF}\
/catalog.json$`);

  /**
   * A class to retrieve the extensions for the current application from the extension manager
   * The extension manager URL is defined in the app-flow.json under the extension property.
   */
  class ExtensionRegistry extends BaseExtensionRegistry {
    static get extensionManagerVersion() {
      return 'v2';
    }

    /**
     * The regex to find openapi3 files;
     * The () group is used to capture the service name from the path.
     * @return {RegExp}
     */
    static get serviceRegex() {
      return serviceRegex;
    }

    /**
     * The regular expresion to find a catalog in a list of extension files
     * @return {RegExp}
     */
    static get catalogRegex() {
      return catalogRegex;
    }

    /**
     * Constructor for a v2 extension
     * @param  {Object} def             definition from the manifest
     * @param  {Array} bundleIds        array of bundle ids
     * @param  {Array} bundledResources array of bundled resources
     * @param  {Object} componentsRequirejs
     * @return {Extension} the new extension
     */
    createExtension(def, appUiInfo, bundleIds, bundledResources, componentsRequirejs) {
      return new ExtensionV2(def, appUiInfo, bundleIds, bundledResources, componentsRequirejs, this);
    }

    /**
     * Initiate loading the manifest by building the URL and doing the fetch call immediately
     * without waiting on the result.
     * @return {Promise}
     */
    initiateLoadManifest() {
      return this.getExtensionAdapter().then((adapter) => {
        if (adapter) {
          // To pass registry to PWA service worker, via ConfigLoader.init(), in extensionConfig
          this.digestLoader = adapter;

          // Only store the promise, don't block on it.
          this.fetchManifestPromise = adapter.initiateFetchManifest();
          return true;
        }

        return false;
      });
    }

    getLoadManifestPromise() {
      return this._loadManifest()
        .then((manifest) => {
          const bundles = {};
          const bundlesInfo = {};
          const bundledResources = {};
          const newAppUiInfo = {};

          // Master list of external components (absolute path)
          const externalComponents = {};
          // Per-extension list of components
          const components = {};

          // replace the appUiInfo array into a map keyed by extension id
          if (manifest.appUiInfo) {
            manifest.appUiInfo.forEach((info) => {
              info.metadata.version = info.version;
              newAppUiInfo[info.id] = info.metadata;
            });
          }
          manifest.appUiInfo = newAppUiInfo;

          // Create a requirejs config with the bundle info from the digest
          manifest.requirejsInfo.forEach((info) => {
            bundlesInfo[info.id] = [];
            const metadata = info.metadata;

            if (metadata) {
              if (metadata.configurations) {
                // Check bundles defined in the extension
                const build = metadata.configurations.build;
                if (build) {
                  if (build.bundles && typeof build.bundles === 'object') {
                    const bundleIds = bundlesInfo[info.id];
                    Object.keys(build.bundles).forEach((bundleId) => {
                      bundleIds.push(bundleId);
                    });
                    Object.assign(bundles, build.bundles);
                  }

                  if (build.bundledResources) {
                    bundledResources[info.id] = build.bundledResources;
                  }
                }
              }

              if (metadata.components) {
                // entries in the components section might used expression like window.vbInitConfig or $initParams
                const componentsDef = ConfigLoader.getEvaluatedSafe(metadata.components);
                Object.keys(componentsDef).forEach((name) => {
                  const componentInfo = componentsDef[name];
                  const paths = componentInfo.requirejs && componentInfo.requirejs.paths;
                  if (Utils.isObject(paths) && Object.keys(paths).length > 0) {
                    let path = paths[name];
                    // For reference component, the path key if not the component name.
                    // For example the name can be "oj-ref-moment" but for the path, the name is "moment"
                    if (!path) {
                      // the logic is to take the first entry, supposedly the only one
                      name = Object.keys(paths)[0];
                      path = paths[name];
                    }

                    if (Utils.isAbsoluteUrl(path)) {
                      // NOTE: If the logic for "keep the newest version of the component" changes, please make
                      // sure src/sw/extensionCacheController's component version checking is synched with it.
                      if (!componentInfo.version) {
                        this.log.warn('Component', name, 'does not have a version property, ignoring it.');
                      } else if (!externalComponents[name]
                          // Only keep the newest version of the component
                          || compareVersions(componentInfo.version, externalComponents[name].version) > 0) {
                        externalComponents[name] = componentInfo;
                      }
                    } else {
                      components[info.id] = Utils.mergeObject(components[info.id] || {}, componentInfo.requirejs);
                    }
                  }
                });
              }
            }
          });

          // Configure the requirejs path and bundle info of all external components
          // For each component only the latest version is used
          const paths = {};
          Object.keys(externalComponents).forEach((name) => {
            const comp = externalComponents[name];
            this.log.info('Registering component', name, 'version', comp.version);
            Object.assign(paths, comp.requirejs.paths);
            Object.assign(bundles, comp.requirejs.bundles);
          });

          if (Object.keys(bundles).length > 0 || Object.keys(paths).length > 0) {
            ConfigLoader.setConfiguration({ paths, bundles });
          }

          // The bundlesInfo will be used during the extension creation the map the bundle URL
          manifest.bundlesInfo = bundlesInfo;
          manifest.bundledResources = bundledResources;

          // Local components will be initialized later when the extension is loaded
          manifest.components = components;

          // We're done using this info, so discard it
          delete manifest.requirejsInfo;

          return manifest;
        })
        .catch((err) => {
          // Swallow the error so that it doesn't break the application, but no extension will be loaded
          this.log.error('Error loading extension registry, no App UI will be loaded', err);
          return {};
        });
    }

    /**
     * For v2, the base path is prefixed with 'ui/'
     * @param  {String} path
     * @param  {Container} container
     * @return {String}
     */
    getBasePathForUi(path, container) {
      return `${Constants.DefaultPaths.UI}${this.getBasePath(path, container)}`;
    }

    /**
     * Retrieve the base path for an extension layout in v2
     * Convert dynamicLayouts/{path} or dynamicLayouts/self/{path} when the container
     * is in an App UI to dynamicLayouts/{extId}/{path}
     * extId is the extension id of the container (could be base)
     * @param  {String} origPath
     * @param  {Container} container
     * @return {String}
     */
    // eslint-disable-next-line class-methods-use-this
    getBasePathForLayout(origPath, container) {
      let path = origPath;

      // The object being extended is either in dynamicLayouts/... or ui/...
      // It is possible the path does not start with ui only when the object being extended is
      // in the unified app. In this case we need prefix the path with ui to find the extension
      // Note that extension id ('base' or an other id) is inserted in path only when needede a few line below
      if (!path.startsWith(Constants.DefaultPaths.LAYOUTS) && !path.startsWith(Constants.DefaultPaths.UI)) {
        path = `${Constants.DefaultPaths.UI}${path}`;
      }

      const pathElements = Utils.addTrailingSlash(path).split('/');

      // If the extensionId is base, we need to insert base into the path,
      // e.g., dynamicLayouts/layoutId => dynamicLayouts/base/layoutId.
      if (container.extensionId === 'base') {
        pathElements.splice(1, 0, 'base');
      } else if (pathElements[1] !== 'base') {
        // substitute the extension id, e.g., dynamicLayouts/self/foo -> dynamicLayouts/extA/foo,
        // except for dynamicLayouts/base/foo
        pathElements[1] = container.extensionId;
      }

      return pathElements.join('/');
    }

    /**
     * Retrieve a map of AppUiInfo for all the App UI available in all the extensions
     * @return {Promise<AppUiInfos>} a promise that resolve with an AppUiInfos
     */
    getAppUiInfos() {
      return super.getAppUiInfos()
        // eslint-disable-next-line arrow-body-style
        .then((appUiInfos) => {
          // For previewing root page, DT needs to disable App UIs
          return this.runtimeEnvironmentCallback().then((re) => re.disableAppUis()).then((result) => {
            // App UIs are disable by returning the empty appUiInfos
            if (result === true) {
              return appUiInfos;
            }

            return this.getExtensions().then((extensions) => {
              // Traverse the array of extension from first to last. The extension manager is responsible
              // for properly ordering this array of extensions given the dependencies in the extension manager.
              extensions.forEach((extension) => {
                const infos = (extension.appUiInfo && Object.values(extension.appUiInfo)) || [];
                if (infos.length > 0) {
                  infos.forEach((info) => {
                    appUiInfos.add(info.id, extension, info);
                  });
                } else {
                  const files = extension.files || [];

                  // Look for the package json files
                  files.forEach((file) => {
                    const match = file.match(appPackageRegex);
                    const id = match && match[1];
                    if (id) {
                      appUiInfos.add(id, extension);
                    }
                  });
                }
              });

              return appUiInfos;
            });
          });
        });
    }

    /**
     * Loads all the extensions for a specific V2 Bundle given its path. It returns a promise
     * that resolves in an array of V2 Bundle Extension objects.
     * @param  {String} path the path of the V2 Bundle Definition for which we are looking for extensions
     * @param  {BundleV2Definition} bundleDefinition the bundle for which the extensions are being loaded
     * @return {Promise} a promise to an array of V2 Bundle Extension objects
     */
    loadTranslationExtensions(path, bundleDefinition) {
      return this.getExtensions().then((extensions) => {
        const promises = [];

        // Calculate the base path for translations resource extensions.
        const basePath = `${Constants.DefaultPaths.TRANSLATIONS}${this.getBasePath(path, bundleDefinition)}`;
        const extensionPath = `${basePath}-x`;
        const Clazz = bundleDefinition.constructor.extensionClass;
        const extPath = `${extensionPath}.js`;

        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          const files = extension.files || [];

          // If the manifest contains an extension for this artifact, creates an extension object for it
          if (files.indexOf(extPath) >= 0) {
            const ext = new (Clazz)(extension, extensionPath, bundleDefinition);
            const promise = ext.load().then(() => ext);
            promises.push(promise);
          }
        });

        // All files are then loaded in parallel
        return Promise.all(promises);
      });
    }

    /**
     * Retrieve a map of all extensions that define translation bundles.
     * @return {Promise<Map<string,object>>} map of extId to extension for all that define a translation bundle
     */
    getTranslations() {
      return this.getExtensions().then((extensions) => {
        const results = {};
        // Traverse the array of extension from first to last. The extension manager is responsible
        // for properly ordering this array of extensions given the dependencies in the extension manager.
        extensions.forEach((extension) => {
          // Look for the translations configuration files
          // translations-config are always located in translations folder and the descriptor
          // is translations-config.json.
          try {
            if (extension.fileExists('translations/translations-config.json')) {
              results[extension.id] = extension;
            }
          } catch (err) {
            // ignore
          }
        });

        return results;
      });
    }
  }

  return ExtensionRegistry;
});

