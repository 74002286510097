'use strict';

define('vb/private/stateManagement/layoutMixin',[], () => {
  /**
   * Mixin that is used by layout container and extension to load translation bundles similarly
   */
  const LayoutMixin = (superclass) => class extends superclass {
    /**
     * overrides the base impl, so we can add "allowSelfRelative: false"
     * MJR: Investigate, is this correct?
     *
     * @returns {Promise<Object>} a promise that resolves with the bundles model
     */
    loadTranslationBundles() {
      return super.loadTranslationBundles({ allowSelfRelative: false });
    }

    /**
     * The name of the resource to be used to load layout or extension layout.
     * @type {string}
     */
    get fullName() {
      return this.name;
    }
  };

  return LayoutMixin;
});

