'use strict';

define('vb/private/services/definition/serviceDefinition',[
  'vb/private/configLoader',
  'vb/private/services/endpoint',
  'vb/private/services/serviceConstants',
  'vb/private/services/serviceUtils',
  'vb/private/services/swaggerUtils',
  'vb/private/services/definition/openApiDefinitionObject',
  'vbsw/private/constants',
], (
  ConfigLoader,
  Endpoint,
  ServiceConstants,
  ServiceUtils,
  SwaggerUtils,
  OpenApiDefinitionObject,
  SWConstants,
) => {
  // const logger = Log.getLogger('/vb/private/services/services');

  /**
   * ServiceDefinition
   */
  class ServiceDefinition extends OpenApiDefinitionObject {
    /**
     *
     * @param name
     * @param path
     * @param protocolRegistry utility that handles vb-specific protocols
     * @param catalogInfo catalog.json information, if any
     * @param openApi abstract definition model, implementation is either OpenApi 2 or OpenApi 3
     * @param relativePath container path, relative to requirejs root
     * @param requestInit fetch options
     * @param {string} namespace
     * @param isUnrestrictedRelative true for application; flows/pages may not load from parent folders (app may).
     */
    constructor(name,
      path, protocolRegistry, catalogInfo, openApi, relativePath, requestInit, namespace, isUnrestrictedRelative) {
      super(name, openApi, null, namespace, relativePath, catalogInfo, isUnrestrictedRelative);

      this._openApi = openApi;
      this._requestInit = requestInit;

      this._protocolRegistry = protocolRegistry;

      this._server = openApi.getServerForProfile(ConfigLoader.activeProfile);

      this._nameForProxy = ServiceUtils.getNameForProxy(name, path, catalogInfo.chain);

      // merge chosen server's "x-vb" info into the global ("info"-level) one
      if (this.server[ServiceConstants.VB_EXTENSIONS]) {
        // the server ones will take precedence
        const mergedExtensions = ServiceUtils
          .mergeExtensions(this.extensions, this.server[ServiceConstants.VB_EXTENSIONS]);

        this.extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo, mergedExtensions);
      } else {
        // we need the augmented info for the 'legacy' syntax, also, for http/https promotion via proxy
        this.extensions = ServiceUtils.augmentExtension(this.nameForProxy, catalogInfo, this.extensions);
      }

      // hack for BUFP-25774 - for business objects; this replaces regex for 'ic/builder' in the plugins
      // @todo: remove business object hack when 'x-vb:authentication' handling is implemented in the runtime
      if (name === 'businessObjects' || path.endsWith('businessObjects/service.json')) {
        this.extensions.authenticationType = SWConstants.AuthenticationType.ORACLE_CLOUD;
      }

      this._endpoints = {};

      openApi.getPathObjects().forEach((pathObject) => {
        const pathKey = pathObject.path;

        pathObject.getOperationObjects().forEach((operationObject) => {
          const operationKey = operationObject.name;

          const endpointId = SwaggerUtils.getEndpointId(pathKey, pathObject, operationObject.name, operationObject);

          // TODO: do we need to preload all ???
          this._endpoints[endpointId] = new Endpoint({
            name: endpointId,
            service: this,
            protocolRegistry,
            pathKey,
            pathObject,
            operationKey,
            operationObject,
            isUnrestrictedRelative: this.isUnrestrictedRelative,
          });
        });
      });
    }

    get server() {
      return this._server;
    }

    get requestInit() {
      return this._requestInit;
    }

    get nameForProxy() {
      return this._nameForProxy;
    }

    /**
     * Gets JSON Object representing OpenApi document defining the service.
     *
     * The following is disabled:
     * If endpointReference parameter is provided, the returned document will have references resolved
     * in the operation object corresponding to the endpointReference.
     *
     * @param {EndpointReference} [endpointReference]
     * @returns {Promise<Object>} OpenApi JSON document
     */
    getDefinition(endpointReference) {
      return Promise.resolve()
        // .then(() => {
        //   if (endpointReference) {
        //     // expand metadata for the endpoint of interest and only then return the OpenApi document (VBS-31587)
        //     const endpoint = this.findEndpoint(endpointReference);
        //     if (endpoint) {
        //       return endpoint.getMetadata();
        //     }
        //   }
        //   return null;
        // })
        .then(() => this._openApi.definition);
    }

    getServers() {
      return this._openApi.getServers();
    }

    isDefined() {
      return !!this.url;
    }

    /**
     * @return {boolean} true if this service definition is visible outside the container that defines it
     *                   (i.e., if an extension can use it).
     */
    isExtensionAccessible() {
      const definitionExtension = this._openApi && this._openApi.getExtensions();
      if ((definitionExtension && definitionExtension.extensionAccess) === true) {
        return true;
      }

      const mergedExtensions = this.catalogInfo && this.catalogInfo.mergedExtensions;
      return (mergedExtensions && mergedExtensions.extensionAccess) === true;
    }

    // eslint-disable-next-line class-methods-use-this
    getEndpointReferenceHash(endpointRef) {
      return endpointRef.operationId;
    }

    findEndpoint(endpointRef) {
      return this._endpoints[this.getEndpointReferenceHash(endpointRef)];
    }
  }

  return ServiceDefinition;
});

