'use strict';

define('vb/extensions/dynamic/private/types/bridges/dynamicLayoutMetadataProviderFactoryBridge',[
  'vb/extensions/dynamic/private/types/bridges/serviceMetadataProviderFactoryBridge',
  'vb/private/utils', 'vb/private/log', 'knockout',
], (ServiceMetadataProviderFactoryBridge, Utils, Log, ko) => {
  const logger = Log.getLogger('/vb/extensions/dynamic/private/types/DynamicLayoutMetadataProviderDescriptor');

  /**
   * a 'bridge' for DynamicLayoutMetadataProviderDescriptor, extends the ServiceMetadataProviderFactoryBridge.
   *
   * - specifies the JET metadata provider factory class
   * - converts the VB declaration into options JET provider understands.
   * - configures the MetadataProviderHelper passed to the JET provider.
   *
   * In addition to what the "Service" one provides, this adds support for JSON-based layout information,
   * and support for configuring the "helper" to load layout-related resources for the JET provider.
   *
   * (also has some deprecated support for specifying an alternate locations for the layout-related files).
   */
  class DynamicLayoutMetadataProviderFactoryBridge extends ServiceMetadataProviderFactoryBridge {
    // eslint-disable-next-line class-methods-use-this
    getDelegatePath() {
      return 'oj-dynamic/providers/DynamicLayoutMetadataProviderFactory';
    }

    /**
     * Overridden to wrap DynamicLayoutMetadataProvider in ResolvedLayoutCachingMetadataProvider.
     *
     * @param options
     * @returns {Promise}
     */
    createMetadataProvider(options) {
      return super.createMetadataProvider(options)
        .then((metadataProvider) => {
          const mdValue = options.metadataDescriptor.getValue();
          const opts = {};

          // wrap each property in a ko observable to set up dependency so when the underlying value
          // changes, ResolvedLayoutCachingMetadataProvider will get notified
          ['objectContext', 'externalValue', 'initialValue'].forEach((key) => {
            if (mdValue[key] !== undefined) {
              opts[key] = ko.pureComputed(function () {
                return mdValue[key];
              });
            }
          });

          // If no ko observables are added, don't wrap the metadata provider in ResolvedLayoutCachingMetadataProvider
          if (Object.keys(opts).length === 0) {
            return metadataProvider;
          }

          return DynamicLayoutMetadataProviderFactoryBridge.getResolvedLayoutCachingMetadataProviderFactory()
            .then((ResolvedLayoutCachingMetadataProviderFactory) => {
              if (!ResolvedLayoutCachingMetadataProviderFactory) {
                return metadataProvider;
              }

              opts.metadataProvider = metadataProvider;
              this.metadataProvider = new ResolvedLayoutCachingMetadataProviderFactory().createMetadataProvider(opts);

              return this.metadataProvider;
            });
        });
    }

    /**
     * Load oj-dynamic/providers/ResolvedLayoutCachingMetadataProviderFactory.
     *
     * @returns {Promise}
     */
    static getResolvedLayoutCachingMetadataProviderFactory() {
      this.getRLCMetadataProviderFactoryPromise = this.getRLCMetadataProviderFactoryPromise
        || Utils.getResource('oj-dynamic/providers/ResolvedLayoutCachingMetadataProviderFactory')
          .catch((error) => {
            logger.warn('Failed to load ResolvedLayoutCachingMetadataProvider.', error);
            return undefined;
          });

      return this.getRLCMetadataProviderFactoryPromise;
    }

    /**
     * two possible cases: service-based, or JSON-based.
     * service: we have an 'endpoint', and we will let our parent (ServiceMetadataProviderDescriptor) handle it
     * JSON: we have a 'path', and we will process it here
     *
     * @param options
     * @returns {Promise<unknown>}
     * @override
     */
    translateOptions(options) {
      const newOptions = options;

      return Promise.resolve()
        .then(() => {
          if (newOptions.path) {
            // remove 'endpoint', in case we have both
            delete newOptions.endpoint;
            // and use the 'options' to specify the helper class
            newOptions.helper = {
              path: 'vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper',
            };
          }

          return super.translateOptions(options);
        });
    }

    /**
     *
     * @param options
     * @override
     * @returns {Promise}
     */
    getHelper(options) {
      return super.getHelper(options)
        .then((helper) => {
          // optional path override, normally not used
          const path = (options.layoutConfig && options.layoutConfig.path);
          // optional default root path override, normally not used
          const root = options.layoutConfig && options.layoutConfig.root;

          /**
           * 'layoutRoot' has different meaning, depending on the helper:
           * - for 'base' helper, its the absolute path to the JSON file.
           * - for 'service' helper, it is the root of the endpoint-derived paths
           * 'layoutPath' is specific to 'service' helper, and overrides the root and endpoint-derived path.
           */

          if (options.path) {
            helper.setLayoutRoot(options.path);
          } else if (path) {
            helper.setLayoutPath(path);
          } else if (root !== undefined && root !== null) {
            helper.setLayoutRoot(root); // allow '' for root
          }
          return helper;
        });
    }

    /**
     * Called by the AbstractMetadataProviderDescriptor when the variable is disposed
     */
    dispose() {
      // call dispose on the metadata provider to clean up
      if (this.metadataProvider) {
        this.metadataProvider.dispose();
      }

      super.dispose();
    }
  }

  return DynamicLayoutMetadataProviderFactoryBridge;
});

