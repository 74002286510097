/* eslint-disable prefer-destructuring, no-underscore-dangle */

'use strict';

define('vbsw/private/fetchHandler',[
  'vbsw/private/utils',
  'vbsw/private/stateCache',
  'vbsw/private/cacheStrategy',
  'vbsw/private/cacheStrategyFactory',
  'vbsw/api/fetchHandlerPlugin',
  'vbsw/private/pwa/applicationCache',
  'vbsw/private/optCacheStrategiesFactory',
  'vbsw/private/constants',
  'vbc/private/log',
  'vbc/private/performance/performance',
  'vbc/private/trace/tracer',
  'vbsw/private/urlMapperClient',
  'jsondiff',
], (Utils, StateCache, CacheStrategy, CacheStrategyFactory, FetchHandlerPlugin, ApplicationCache,
  OptCacheStrategiesFactory, Constants, Log, Performance, Tracer, UrlMapperClient, JsonDiff) => {
  const MAX_RETRIES = 2;
  const SERVICE_WORKER_CONFIG = 'vbServiceWorkerConfig';
  const SERVICE_WORKER_PLUGIN_INFOS = 'vbServiceWorkerPluginInfos';
  const FORCE_OFFLINE_FLAG = 'vbForceOfflineFlag';
  let logger;

  // used by installPlugins to determine if a plugin has changed
  const jsonDiff = JsonDiff.create({
    arrays: {
      detectMove: false,
    },
    cloneDiffValues: false,
  });

  /**
   * This class is used by the service worker to handle all the fetch requests. In the case where the service
   * worker is not supported by the browser, the PersistenceManager from the offline toolkit can be used to
   * emulate the service worker and this class can also be used to handle fetch requests.
   */
  class FetchHandler {
    /**
     * Create an instance of FetchHandler.
     *
     * @param scope the scope of the service worker
     * @param config configuration object for the service worker
     * @param persistenceManager the PersistenceManager used to emulate the service worker functionality
     */
    constructor(scope, config, persistenceManager) {
      // set up log configuration for Service Worker thread loggers
      if (config) {
        if (config.logConfig && config.logConfig.level) {
          Log.setMinimumLevel(config.logConfig.level);
        }
        Log.setConfig(config.log);
      }
      logger = Log.getLogger('/vbsw/private/fetchHandler');
      logger.info('FetchHandler scope:', scope, 'config:', config);
      this.scope = scope;
      this.config = config;
      this.persistenceManager = persistenceManager;

      // set up browser fetch
      this.setupBrowserFetch(persistenceManager);

      this.plugins = [];

      // for emulation mode and unit tests, we need to make sure the stateCache and cacheStrategy are
      // created since restore won't be called
      this.stateCache = new StateCache();
      this.cacheStrategy = CacheStrategyFactory.createCacheStrategy(this);

      // mapping between a request and its associated originating client
      this.requestInfoMap = new Map();

      // primary offline handler
      this.offlineHandler = null;

      // seconary offline handler
      this.secondaryOfflineHandler = null;

      // set up vb.perf object to access performance data for SW
      if (Utils.isWorkerThread()) {
        // eslint-disable-next-line no-restricted-globals
        Performance.init(self.vb, config && config.performanceConfig);
      }

      // used to reverse-map URLs to 'vb-init-extension' information
      this.urlMapperClient = new UrlMapperClient(this.config);
    }

    /**
     * Set up browserFetch to either point to browserFetch from the persistence manager or the actual browser fetch.
     *
     * @param persistenceManager the persistence manager
     */
    setupBrowserFetch(persistenceManager) {
      const pm = persistenceManager;

      if (pm) {
        this.browserFetch = pm.browserFetch.bind(pm);

        // override the pm browserFetch to call handleRequest on the fetch handler
        pm.browserFetch = (request) => {
          // look up the associated info for the request
          const { client, offlineHandlerStack = [] } = this.getRequestInfo(request) || {};

          // skip offline handler if there offline handler stack is empty
          const skipOfflineHandler = offlineHandlerStack.length === 0;

          return this.handleRequest(request, client, skipOfflineHandler);
        };
      } else {
        // service worker won't let you directly point to fetch so use a wrapper function instead
        this.browserFetch = (request) => fetch(request);
      }
    }

    /**
     * Called during the service worker installation phase to set up the caches and import additional script
     * such as loading the offline handler.
     *
     * @returns {Promise}
     */
    install() {
      const installMark = ['fetchHandler.install'];
      return Promise.resolve()
        .then(() => Performance.markStart(installMark))
        .then(() => this.deleteStaleCaches())
        // need to call restore to preload any external scripts since we can't load any more scripts after installation
        .then(() => this.restore(true))
        .then(() => this.initApplicationCache())
        .then(() => Performance.markEnd(installMark));
    }

    /**
     * @returns {Promise<StateCache>} a Promise to open state cache
     */
    openStateCache() {
      const cacheName = this.getStateCacheName();
      logger.info('Opening state cache:', cacheName);
      return caches.open(cacheName)
        .then((cache) => {
          this.stateCache = new StateCache(cache);
          return this.stateCache;
        });
    }

    /**
     * @returns {Promise<void>} a Promise to open Service Worker caches required by the application.
     */
    openCaches(isOnInstall) {
      return this.openStateCache()
        .then((stateCache) => {
          // cache the config object on install
          if (isOnInstall) {
            return stateCache.put(SERVICE_WORKER_CONFIG, this.config);
          }
          // otherwise, restore the config object from cache
          return stateCache.get(SERVICE_WORKER_CONFIG).then((cachedConfig) => {
            if (!cachedConfig) {
              logger.info('Service Worker: Failed to load cached configuration');
              this.config = {};
            } else {
              this.config = cachedConfig || {};
              logger.info('Service Worker Cached Configuration:', this.config);
            }
          });
        })
        .then(() => {
          if (this.config.isPwa) {
            return this.getApplicationCache()
              .then((cache) => {
                if (!cache) {
                  throw new Error('PWA: missing application cache.');
                }
                this.setApplicationCache(cache);
              })
              .catch((err) => {
                // Don't log anything unless this is PWA error
                if (err && err.message && err.message.startsWith('PWA')) {
                  logger.error('PWA: Failed to initApplicationCache:', err);
                }
              });
          }
          return undefined;
        });
    }

    initTracer() {
      if (Utils.isWorkerThread()) {
        return Tracer.init(this.config.traceOptions);
      }
      return Promise.resolve();
    }

    /**
     * Restore state from the cache. This is called during the oninstall event (isOnInstall is true) to preload
     * all scripts. This is also called when a service worker is activated to restore the state of the fetch handler.
     *
     * @param isOnInstall true, if restore is called during oninstall
     * @param skipInstallingPlugins if true, plugins won't be reinstalled
     * @returns {Promise}
     */
    restore(isOnInstall, skipInstallingPlugins = false) {
      return this.initTracer()
        .then(() => this.openCaches(isOnInstall))
        .then(() => {
          // create the cacheStrategy based on the restored config
          this.cacheStrategy = CacheStrategyFactory.createCacheStrategy(this);

          // set up any require config before loading the offline handler and plugins
          this.setRequireConfig();

          const promises = [];

          // install the plugins, if isOnInstall is true, pre install any external plugins since we can't
          // load any more scripts after the installation phase
          if (!skipInstallingPlugins) {
            promises.push(this.installPlugins(isOnInstall ? this.config.plugins : undefined));
          }
          // install the offline handler
          promises.push(this.installOfflineHandler(this.config));

          return Promise.all(promises);
        });
    }

    /**
     * The name of the versioned application cache in the form: scope-VERSION-application.
     * @returns {string} the name of the application cache
     */
    getApplicationCacheName() {
      return this.getCacheName('application');
    }

    /**
     * The name of the versioned state cache in the form: scope-VERSION-state
     * @returns {string} the name of the state cache
     */
    getStateCacheName() {
      return this.getCacheName('state');
    }

    /**
     * Constructs cache name in the form: scope-VERSION-type
     * @param type cache type, for example: 'state' or 'application'
     * @returns {string} the name of the cache
     */
    getCacheName(type) {
      let cacheName = '';
      if (this.scope) {
        cacheName += `${this.scope}-`;
      }
      if (this.config.version) {
        cacheName += `${this.config.version}-`;
      }
      cacheName += type;
      return cacheName;
    }

    /**
     * @returns {Promise<void|Cache>} a promise to an application cache for PWA, or a Promise to an undefined.
     */
    getApplicationCache() {
      if (this.config.isPwa) {
        const appCacheName = this.getApplicationCacheName();
        logger.info('PWA: opening application cache:', appCacheName);

        return caches.open(appCacheName)
          .catch((err) => {
            // log and ignore the error
            logger.error('PWA: Failed to getApplicationCache:', err);
          });
      }
      return Promise.resolve();
    }

    /**
     * Delete out-of-date caches during service worker install.
     *
     * @returns {Promise<void>} a promise to delete all stale caches. State cache and application cache is considered
     * stale on every install, since install is triggered by one of:
     * - modulePath change, for example, when custom RT version is used, such as:
     * https://xyz.com/ic/builder/rt/xyz/1.0/mobileApps/abc/version_4473741334620356740/resources/package/
     * - jetPath change, when JET version changes
     * - baseUrlToken or nonce change, when a new version of an application is staged/published
     * - debug flag change
     */
    deleteStaleCaches() {
      // TODO: deleting caches should be happening during activate, not install
      logger.info('Attempting to delete stale caches for version:', this.config.version, 'scope:',
        this.scope);
      return caches.keys().then((cacheNames) => Promise.all(cacheNames.map((cacheName) => {
        logger.info('Checking cache:', cacheName);
        if (this.isStaleStateCache(cacheName) || this.isStaleApplicationCache(cacheName)) {
          logger.info('Deleting cache:', cacheName);
          return caches.delete(cacheName);
        }
        return Promise.resolve();
      })));
    }

    /**
     * For a given application, state cache is considered stale on every install, since install is triggered
     * on every RT version change.
     *
     * scope-VERSION-state
     *
     * @param cacheName cache name to check
     * @returns {Boolean} true, if cacheName corresponds to a stale state cache
     */
    isStaleStateCache(cacheName) {
      return cacheName.startsWith(this.scope) && cacheName.endsWith('state');
    }

    /**
     * Application cache stores versioned application assets. It is considered stale every time Service Worker
     * is installed (when baseUrlToken changes) to guarantee that the cache contains the latest application assets.
     * So application cache for a given application is considered stale on every install.
     *
     * scope-VERSION-application
     *
     * @param cacheName cache name to check
     * @returns {Boolean} true, if cacheName corresponds to a stale application cache
     */
    isStaleApplicationCache(cacheName) {
      return cacheName.startsWith(this.scope) && cacheName.endsWith('application');
    }

    /**
     * @returns {Promise<void>} a promise to cache application assets or undefined, if this is not a PWA
     */
    initApplicationCache() {
      return Promise.resolve()
        .then(() => {
          if (this.config.isPwa) {
            return this.cacheOnInstall();
          }
          return undefined;
        })
        .catch((err) => {
          // Don't log anything unless this is PWA
          if (err && err.message && err.message.startsWith('PWA')) {
            logger.error('PWA: Failed to initApplicationCache:', err);
          }
        });
    }

    /**
     * Sets a cache to use for application static assets,
     * @param serviceWorkerCache the ServiceWorker cache created for application assets
     */
    setApplicationCache(serviceWorkerCache) {
      if (serviceWorkerCache) {
        logger.info('PWA: setApplicationCache()');
        this.appCache = new ApplicationCache(serviceWorkerCache);
      }
    }

    /**
     * Return the configuration object for the service worker.
     *
     * @returns {*|{}}
     */
    getConfig() {
      return this.config;
    }

    /**
     * Set up any externally provided require config.
     */
    setRequireConfig() {
      if (this.config.requireConfig) {
        require.config(this.config.requireConfig);
      }
    }

    /**
     * Retrieve the cached plugin urls.
     *
     * @returns {Promise}
     */
    getCachedPluginInfos() {
      return this.stateCache.get(SERVICE_WORKER_PLUGIN_INFOS);
    }

    /**
     * Cache the array of plugin urls so it can be retrieved the next time the service worker starts.
     *
     * @param pluginInfos an array of plugin infos to cache
     * @returns {Promise.<T>|Promise<R>|Promise<void>}
     */
    cachePluginInfos(pluginInfos) {
      return this.stateCache.put(SERVICE_WORKER_PLUGIN_INFOS, pluginInfos);
    }

    /**
     * Load and install the plugin at the given index.
     *s
     * @param pluginInfo the info of the plugin to install
     * @param index the index where the plugin should be installed
     * @param initCache if initCache is true, call initCache() on the newly created plugin to initialize the cache
     * @returns {Promise}
     */
    installPlugin(pluginInfo, index, initCache) {
      let pluginUrl;
      let params;

      if (typeof pluginInfo === 'object') {
        pluginUrl = pluginInfo.url;
        params = pluginInfo.params;
      } else {
        pluginUrl = pluginInfo;
      }

      return Utils.getResource(pluginUrl).then((PluginClass) => {
        logger.info('Plugin loaded from', pluginUrl);

        // create a context object containing this fetchHandler and a namespaced stateCache
        const context = {
          fetchHandler: this,
          stateCache: this.stateCache.createNamespacedCache(pluginUrl),
        };

        const plugin = new PluginClass(context, params);
        this.plugins[index] = plugin;

        // if initCache is true, call initCache() on the newly created plugin to initialize the cache
        return initCache ? plugin.initCache() : undefined;
      }).catch((err) => {
        logger.error('Failed to load plugin from', pluginUrl, err);
      });
    }

    /**
     * Load and install plugins specified in pluginInfos. A plugin info can be either an url string or
     * and object containing url and params properties. The params property will be passed to the
     * constructor of the plugin. For example:
     *
     * [
     *   'vbsw/plugin1',
     *   {
     *     url: 'vbsw/plugin2',
     *     params:  {
     *       foo: 'bar'
     *     }
     *   }
     * ]
     *
     * Note that this method will replace all the existing installed plugins.
     *
     * @param pluginInfos an array of plugin info.
     * @param reload if true, force reload all the plugins, otherwise, only reload plugins whose metadata has changed
     * @returns {Promise}
     */
    installPlugins(pluginInfos, reload = false) {
      return this.getCachedPluginInfos().then((cachedPluginInfos) => {
        const oldPluginInfos = cachedPluginInfos || pluginInfos;
        const newPluginInfos = pluginInfos || oldPluginInfos || [];
        const oldPlugins = this.plugins;
        const promises = [];
        this.plugins = new Array(newPluginInfos.length);

        for (let i = 0; i < newPluginInfos.length; i += 1) {
          const info = newPluginInfos[i];

          // look up the existing plugin whose info hasn't changed
          const index = oldPluginInfos.findIndex((oldInfo) => !jsonDiff.diff(oldInfo, info));

          // don't reuse the plugin if reload is true
          if (!reload && index !== -1) {
            this.plugins[i] = oldPlugins[index];
          }

          if (!this.plugins[i]) {
            promises.push(this.installPlugin(info, i, reload));
          }
        }

        return Promise.all(promises).then(() => this.cachePluginInfos(newPluginInfos));
      });
    }

    /**
     * Uninstall currently installed plugins. This is used for testing purpose.
     *
     * @returns {Promise}
     */
    uninstallPlugins() {
      this.plugins = [];
      return Promise.resolve();
    }

    /**
     * enable URL Mapping.
     *
     * called either directly by EmulatedServiceWorkerWrapper, or indirectly via postMessage from
     * BrowserServiceWorkerWrapper (see self.addEventListener('message'..) in vbServiceWorker.
     *
     * @returns {*}
     */
    activateUrlMapping() {
      return this.urlMapperClient.activate();
    }

    /**
     * Return an array of all the registered offline handlers.
     *
     * @returns {Array}
     */
    getOfflineHandlers() {
      const offlineHandlers = [];

      // push the secondary offline handler on to the stack first if exist
      if (this.secondaryOfflineHandler) {
        offlineHandlers.push(this.secondaryOfflineHandler);
      }

      // push the primary offline handler onto the stack if exist
      if (this.offlineHandler) {
        offlineHandlers.push(this.offlineHandler);
      }

      return offlineHandlers;
    }

    /**
     * Load and install the offline handler using information in the handlerInfo.
     *
     * @param handlerInfo and object containing information for the offline handler
     * @returns {Promise}
     */
    installOfflineHandler(handlerInfo) {
      return Promise.resolve().then(() => {
        if (!this.offlineHandler) {
          const handlerUrl = handlerInfo.offlineHandler;

          if (handlerUrl) {
            // set up the requirejs config for offline toolkit so the handler can be loaded if we are not running in
            // emulation mode
            if (!this.persistenceManager) {
              requirejs.config({
                map: {
                  '*': {
                    opt: 'persist', // for backwards compatibility with opt prefix
                  },
                },
              });
            }

            return Utils.getResource(handlerUrl).then((Module) => {
              let module;
              if (Module) {
                // Module can be either a constructor or a singleton
                module = typeof Module === 'function' ? new Module() : Module;
              } else {
                module = {};
              }

              if (typeof module.createOfflineHandler === 'function') {
                // make sure we initialize the PersistenceManager first
                return this.initializePersistenceManagerWithStore(module)
                  .then(() => module.createOfflineHandler()
                    .then((handler) => {
                      this.offlineHandler = handler;
                    }));
              }

              return undefined;
            }).catch((err) => {
              logger.info(`Failed to load offline handler from ${handlerUrl}: ${err}`);
              this.offlineHandler = null;
            }).finally(() => {
              // if no offline handler is registered but useCacheResponseWhenOfflineHeaderEnabled
              // config option is set to true, make sure we initialize the persistence manager so
              // we can use it to cache responses with the special header
              if (!this.offlineHandler && this.useCacheResponseWhenOfflineHeaderEnabled) {
                return this.initializePersistenceManagerWithStore();
              }

              return undefined;
            });
          }
        }

        return undefined;
      });
    }

    /**
     * Uninstall the offline handler.
     *
     * @return {Promise}
     */
    uninstallOfflineHandler() {
      this.offlineHandler = null;
      return Promise.resolve();
    }

    /**
     * Install a secondary offline handler defined in an app UI.
     *
     * @param handlerUrl the url from which to load the offline handler
     * @returns {Promise}
     */
    installSecondaryOfflineHandler(handlerUrl) {
      return Promise.resolve().then(() => {
        if (handlerUrl) {
          return Utils.getResource(handlerUrl).then((Module) => {
            let module;
            if (Module) {
              // Module can be either a constructor or a singleton
              module = typeof Module === 'function' ? new Module() : Module;
            } else {
              module = {};
            }

            if (typeof module.createOfflineHandler === 'function') {
              return module.createOfflineHandler()
                .then((handler) => {
                  this.secondaryOfflineHandler = handler;
                });
            }

            return undefined;
          }).catch((err) => {
            logger.info(`Failed to load offline handler from ${handlerUrl}: ${err}`);
          });
        }

        return undefined;
      });
    }

    /**
     * Uninstall the secondary offline handler.
     *
     * @returns {Promise}
     */
    uninstallSecondaryOfflineHandler() {
      return Promise.resolve().then(() => {
        this.secondaryOfflineHandler = null;
      });
    }

    /**
     * Return a promise that resolves to true if the PersistenceManager is online and false otherwise.
     *
     * @returns {Promise}
     */
    isOnline() {
      return this.initializePersistenceManager().then((pm) => pm.isOnline());
    }

    /**
     * Get the cached force offline flag.
     *
     * @returns {Promise}
     */
    getCachedForceOfflineFlag() {
      return this.stateCache.get(FORCE_OFFLINE_FLAG);
    }

    /**
     * Cache the force offline flag.
     *
     * @param flag force offline flag
     * @returns {Promise.<Boolean>}
     */
    cacheForceOfflineFlag(flag) {
      return this.stateCache.put(FORCE_OFFLINE_FLAG, flag);
    }

    /**
     * If the flag is true, force the PersistenceManager offline and vice versa.
     *
     * @param flag if true, force the PersistenceManager offline and vice versa.
     * @returns {Promise}
     */
    forceOffline(flag) {
      return this.initializePersistenceManager().then((pm) => pm.forceOffline(flag))
        .then(() => this.cacheForceOfflineFlag(flag));
    }

    /**
     * Dynamically load the PersistenceSyncManager.
     *
     * @returns {*}
     */
    getPersistenceSyncManager() {
      if (!this.persistSyncMgrPromise) {
        this.persistSyncMgrPromise = Utils.getResource('persist/impl/PersistenceSyncManager')
          .then((PersistenceSyncManager) => new PersistenceSyncManager());
      }
      return this.persistSyncMgrPromise;
    }

    /**
     * Dynamically load the PersistenceUtils.
     *
     * @returns {*}
     */
    getPersistenceUtils() {
      if (!this.persistUtilsPromise) {
        this.persistUtilsPromise = Utils.getResource('persist/persistenceUtils');
      }
      return this.persistUtilsPromise;
    }

    /**
     * Deserialize the given request JSON object into a Request instance.
     *
     * @param requestJson request json to deserialize
     * @returns {Promise<Request>}
     */
    deserializeRequest(requestJson) {
      return Promise.resolve().then(() => {
        if (Utils.isWorkerThread()) {
          return this.getPersistenceUtils()
            .then((PersistenceUtils) => PersistenceUtils.requestFromJSON(requestJson));
        }

        return requestJson;
      });
    }

    /**
     * If running on the service worker thread, serialize the given result from a sync manager operation by
     * serializing any Request or Response instance into a JSON object so it can be returned to the main thread
     * via postMessage.
     *
     * @param result
     * @returns {Promise}
     */
    serializeSyncOperationResult(result) {
      return Promise.resolve().then(() => {
        if (result && Utils.isWorkerThread()) {
          return this.getPersistenceUtils().then((PersistenceUtils) => {
            if (Array.isArray(result)) {
              const serializedResult = [];
              const promises = result.map((entry) => this.serializeSyncOperationResult(entry)
                .then((serializedEntry) => {
                  serializedResult.push(serializedEntry);
                }));
              return Promise.all(promises).then(() => serializedResult);
            }

            if (result instanceof Request) {
              return PersistenceUtils.requestToJSON(result);
            }

            if (result instanceof Response) {
              return PersistenceUtils.responseToJSON(result);
            }

            if (Utils.isObject(result)) {
              const serializedResult = {};
              const promises = Object.keys(result)
                .map((key) => this.serializeSyncOperationResult(result[key])
                  .then((serializedProp) => {
                    serializedResult[key] = serializedProp;
                  }));

              return Promise.all(promises).then(() => serializedResult);
            }

            if (typeof result === 'function') {
              return null;
            }

            return result;
          });
        }

        return result;
      });
    }

    /**
     * Invoke PersistenceSyncManager.sync.
     *
     * Note: The client argument is passed in by Utils.invokeMethod.
     *
     * @param options any options to be passed to the persistence sync manager
     * @param client the originating client of this sync call
     */
    syncOfflineData(options, client) {
      if (this.persistenceManagerPromise) {
        return this.persistenceManagerPromise.then((pm) => Utils.getResource('persist/impl/PersistenceSyncManager')
          .then((PersistenceSyncManager) => {
            // create a PersistenceSyncManager that use our FetchHandler to perform browser fetch
            const syncMgr = new PersistenceSyncManager(
              pm.isOnline.bind(pm),
              // call back into our handleRequest and don't skip the offline handler so the request
              // can be handled by the response proxy
              (request) => this.handleRequest(request, client, false),
              pm.getCache.bind(pm),
            );

            this.getOfflineHandlers().forEach((offlineHandler) => {
              if (typeof offlineHandler.beforeSyncRequestListener === 'function') {
                syncMgr.addEventListener('beforeSyncRequest',
                  offlineHandler.beforeSyncRequestListener.bind(offlineHandler));
              }

              if (offlineHandler.afterSyncRequestListener === 'function') {
                syncMgr.addEventListener('syncRequest',
                  offlineHandler.afterSyncRequestListener.bind(offlineHandler));
              }
            });

            return syncMgr.sync(options)
              .catch((err) => this.serializeSyncOperationResult(err)
                .then((serializedErr) => {
                  throw serializedErr;
                }));
          }));
      }

      return Promise.reject(new Error('Cannot invoke sync before the PersistenceManager is initialized.'));
    }

    /**
     * Invoke PersistenceSyncManager.getSyncLog.
     *
     * @returns {Promise}
     */
    getOfflineSyncLog() {
      return this.getPersistenceSyncManager()
        .then((syncMgr) => syncMgr.getSyncLog())
        .then((syncLog) => this.serializeSyncOperationResult(syncLog));
    }

    /**
     * Invoke PersistenceSyncManager.insertRequest.
     *
     * @param requestData
     * @param options
     * @returns {Promise}
     */
    insertOfflineSyncRequest(requestData, options) {
      return this.deserializeRequest(requestData)
        .then((request) => this.getPersistenceSyncManager()
          .then((syncMgr) => syncMgr.insertRequest(request, options))
          .then((result) => this.serializeSyncOperationResult(result)));
    }

    /**
     * Invoke PersistenceSyncManager.removeRequest.
     *
     * @param requestId
     * @returns {Promise}
     */
    removeOfflineSyncRequest(requestId) {
      return this.getPersistenceSyncManager()
        .then((syncMgr) => syncMgr.removeRequest(requestId))
        .then((result) => this.serializeSyncOperationResult(result));
    }

    /**
     * Invoke PersistenceSyncManager.updateRequest.
     *
     * @param requestId
     * @param requestData
     * @returns {Promise}
     */
    updateOfflineSyncRequest(requestId, requestData) {
      return this.deserializeRequest(requestData)
        .then((request) => this.getPersistenceSyncManager()
          .then((syncMgr) => syncMgr.updateRequest(requestId, request))
          .then((result) => this.serializeSyncOperationResult(result)));
    }

    /**
     * Set options on the offline handler.
     *
     * @param options options to be set on the offline handler
     * @returns {Promise}
     */
    setOfflineHandlerOptions(options) {
      return Promise.resolve().then(() => {
        if (this.offlineHandler && typeof this.offlineHandler.setOptions === 'function') {
          return this.offlineHandler.setOptions(options);
        }

        return undefined;
      });
    }

    /**
     * Set options on the secondary offline handler.
     *
     * @param options options to be set on the secondary offline handler
     * @returns {Promise}
     */
    setSecondaryOfflineHandlerOptions(options) {
      return Promise.resolve().then(() => {
        if (this.secondaryOfflineHandler && typeof this.secondaryOfflineHandler.setOptions === 'function') {
          return this.secondaryOfflineHandler.setOptions(options);
        }

        return undefined;
      });
    }

    /**
     * Initialize the PersistenceManager and register a default persistence store.
     *
     * @param offlineModule module containing offline related code
     * @returns {Promise<any>}
     */
    initializePersistenceManagerWithStore(offlineModule) {
      return this.initializePersistenceManager()
        .then((pm) => FetchHandler.registerDefaultPersistenceStoreFactory(offlineModule)
          .then(() => pm));
    }

    /**
     * Initialize the PersistenceManager without registering a default persistence store.
     *
     * @returns {Promise}
     */
    initializePersistenceManager() {
      if (!this.persistenceManagerPromise) {
        if (this.persistenceManager) {
          // don't initialize the PersistenceManager again if we are running in emulation mode
          this.persistenceManagerPromise = Promise.resolve(this.persistenceManager);
        } else {
          this.persistenceManagerPromise = Utils.getResources(['persist/persistenceManager',
            'persist/impl/PersistenceSyncManager', 'persist/defaultResponseProxy',
          ])
            .then((modules) => {
              const [PersistenceManager,
                // PersistenceSyncManager must be loaded during ServiceWorker's install event
                PersistenceSyncManager] = modules; // eslint-disable-line no-unused-vars
              // set up browserFetch since persistence manager overrides it
              this.setupBrowserFetch(PersistenceManager);

              return PersistenceManager.init().then(() => this.getCachedForceOfflineFlag().then((flag) => {
                // if there's a cached force offline flag, apply it here
                if (flag !== undefined) {
                  PersistenceManager.forceOffline(flag);
                }
              })).then(() => PersistenceManager);
            });
        }
      }

      return this.persistenceManagerPromise;
    }

    /**
     * Register the default persistence store factory. This method will first call getDefaultPersistenceFactory method
     * on the module for the offline handler if it exists. Otherwise, it defaults to registering
     * 'persist/pouchDBPersistenceStoreFactory'.
     *
     * @param offlineModule module containing offline related code
     * @returns {*}
     */
    static registerDefaultPersistenceStoreFactory(offlineModule) {
      return Promise.resolve()
        .then(() => {
          if (offlineModule && typeof offlineModule.getDefaultPersistenceStoreFactory === 'function') {
            return offlineModule.getDefaultPersistenceStoreFactory();
          }
          return null;
        })
        .catch((err) => {
          // log the error
          logger.error(err);
          return null;
        })
        .then((storeFactoryOverride) => storeFactoryOverride
          || Utils.getResource('persist/pouchDBPersistenceStoreFactory'))
        .then((storeFactory) => Utils.getResource('persist/persistenceStoreManager')
          .then((PersistenceStoreManager) => {
            PersistenceStoreManager.registerDefaultStoreFactory(storeFactory);
          }));
    }

    /**
     * Retrieves requested URL and adds it to service worker's state cache.
     * @param url
     * @returns {Promise} a promise to cache a file
     */
    cacheFile(url) {
      if (url) {
        return this.stateCache.add(url);
      }
      return Promise.resolve();
    }

    /**
     * Creates a cloned request as a workaround for the whatwg-fetch polyfill issue,
     * where the polyfill can't clone request with FormData.  This workaround can be
     * removed, once the original polyfill issue is addressed.
     * @param url
     * @param config
     * @returns {Request} cloned request
     */
    // eslint-disable-next-line class-methods-use-this
    createRequest(url, config) {
      const clonedRequest = new Request(url, config);
      if (config.bodyFormData) {
        clonedRequest._bodyFormData = config.bodyFormData;
        clonedRequest._bodyInit = config.bodyInit;
      }

      // attach body to the request if attachOriginalBody is true
      if (config.attachOrignalBody) {
        clonedRequest.originalBody = config.body;
      }

      return clonedRequest;
    }

    /**
     * Clone the given request.
     *
     * @param request the request to be cloned
     * @return {Promise}
     */
    cloneRequest(request) {
      return FetchHandlerPlugin.getRequestConfig(request, this)
        .then((config) => this.createRequest(config.url, config))
        .catch((err) => {
          logger.error(err);

          // simply return null if the request cannot be cloned
          return null;
        });
    }

    /**
     * Determines whether the fetch polyfill is being used.
     * @returns {Boolean} true if the fetch polyfill is defined, false otherwise
     */
    isFetchPolyfill() {
      return this.persistenceManager
        && this.persistenceManager._browserFetchFunc
        && this.persistenceManager._browserFetchFunc.polyfill;
    }

    /**
     * Perform the actual fetch.
     *
     * @param request the fetch request
     * @returns {Promise}
     */
    fetch(request) {
      // work around an issue in chrome where the dev tool makes a request with cache set to only-if-cached
      // and the mode not set to same-origin which will always fail
      if (request.cache === 'only-if-cached' && request.mode !== 'same-origin') {
        logger.info('Skip request url:', request.url, 'cache:', request.cache, 'mode:', request.mode);
        return Promise.resolve(new Response(null, { status: 200, statusText: 'OK' }));
      }

      // PWA: dynamically cache CDN files - this needs to happen before custom offline handlers are called
      return Promise.resolve()
        .then(() => this.fetchAndCache(request))
        .then((response) => {
          if (response) {
            return response;
          }

          // else call browser fetch
          return this.browserFetch(request);
        });
    }

    /**
     * Invoke handleRequestHook on all the installed plugins.
     *
     * @param request the request to be handled by the plugins, maybe replaced by some plugins
     * @param client the client for the originator of the request
     * @returns {Promise.<Request>}
     */
    handleRequestHook(request, client) {
      // execute the plugins in order by chaining the promises
      return this.plugins.reduce((promise, plugin) => promise
        .then((r) => (!plugin ? r : plugin.handleRequestHook(r, client)
          .then((r2) => r2 || r)
          .catch((err) => {
            // log the error and prevent the bad plugin from breaking everyone
            logger.error(err);
            return r;
          }))), Promise.resolve(request));
    }

    /**
     * Invoke handleResponseHook on all the installed plugins. This method will return true if any of
     * the plugins returns true which means the request should be retried.
     *
     * @param response the response to be handled by the plugins
     * @param origRequest the original request
     * @param request the modified request
     * @param client the client for the originator of the request
     * @returns {Promise.<Boolean>}
     */
    handleResponseHook(response, origRequest, request, client) {
      // execute the plugins in order by chaining the promises
      return this.plugins.reduce((promise, plugin) => promise
        .then((retry) => (!plugin ? retry : plugin.handleResponseHook(response, origRequest, request, client)
          .catch((err) => {
            // log the error and prevent the bad plugin from breaking everyone
            logger.error(err);
            return false;
          })
          .then((localRetry) => localRetry || retry))), Promise.resolve(false));
    }

    /**
     * Invoke handleErrorHook on all the installed plugins. This method will return true if any of
     * the plugins returns true which means the request should be retried.
     *
     * @param error error to handle
     * @param origRequest original request
     * @param request the modified request
     * @param client the client for the originator of the request
     * @returns {Promise.<Boolean>}
     */
    handleErrorHook(error, origRequest, request, client) {
      // execute the plugins in order by chaining the promises
      return this.plugins.reduce((promise, plugin) => promise
        .then((retry) => (!plugin ? retry : plugin.handleErrorHook(error, origRequest, request, client)
          .catch((err) => {
            // log the error and prevent the bad plugin from breaking everyone
            logger.error(err);
            return false;
          })
          .then((localRetry) => localRetry || retry))), Promise.resolve(false));
    }

    /**
     * Remember the given info associated with a request
     *
     * @param request the request
     * @param info info associated with the request
     * @private
     */
    saveRequestInfo(request, info) {
      this.requestInfoMap.set(request, info);
    }

    /**
     * Return the info associated with the given request.
     *
     * @param request the request for looking up the associated info
     * @returns {*}
     * @private
     */
    getRequestInfo(request) {
      return this.requestInfoMap.get(request);
    }

    /**
     * Delete the info associated with the given request.
     *
     * @param request the request for the info to delete
     * @private
     */
    deleteRequestInfo(request) {
      this.requestInfoMap.delete(request);
    }

    /**
     * Returns true if there's a registered offline handler.
     *
     * @returns {*|boolean}
     */
    get hasOfflineHandler() {
      return (this.offlineHandler && typeof this.offlineHandler.handleRequest === 'function')
        || (this.secondaryOfflineHandler && typeof this.secondaryOfflineHandler.handleRequest === 'function');
    }

    /**
     * Return true is processUseCacheResponseWhenOfflineHandler is enabled.
     *
     * @returns {*|{}|boolean}
     */
    get useCacheResponseWhenOfflineHeaderEnabled() {
      return this.config && this.config.useCacheResponseWhenOfflineHeaderEnabled;
    }

    /**
     * Handle the request by performing the following:
     * 1. Clone the original request
     * 2. Invoke handleRequestHook on all registered handler plugin
     * 3. Invoke the browser fetch
     * 4. Invoke handleResponseHook on all registered handler plugins. Retry the request if any handleResponseHook call
     *    returns true
     * 5. Invoke handleErrorHook on all registered handler plugins if the fetch request fails. Retry the request if
     *    any handleErrorHook call returns true
     *
     * @param origRequest the original request object
     * @param client the originating client of the request which can be used to post message back to the client
     * @param skipOfflineHandler if true, skip forwarding the request to the offline handler
     * @param retryCount current retry count
     * @returns {Promise}
     */
    handleRequest(origRequest, client, skipOfflineHandler, retryCount = 0) {
      // console.log(`handleRequest() url = ${origRequest.url}`);
      return this.checkCache(origRequest.url).then((cachedResponse) => {
        if (cachedResponse) {
          return cachedResponse;
        }
        // can't modify navigate or OPTIONS request so directly call browser fetch on the request
        if (origRequest.mode === 'navigate' || origRequest.method === 'OPTIONS') {
          return this.fetch(origRequest)
            // check PWA cache again, because this could be request for index.html that failed due to false positive
            // online check
            .catch((err) => this.checkCache(origRequest.url, Constants.LOOKUP_INDEX_MODE)
              .then((res) => {
                if (res !== undefined) {
                  return res;
                }
                throw err;
              }));
        }

        // for PWA or if offline support is enabled, directly process the request containing the
        // vb-use-cached-response-when-offline header, e.g., currentuser request or extension manager registry request,
        // using the default
        // CacheIfOfflineStrategy to cache the response
        if (!skipOfflineHandler
          && (this.useCacheResponseWhenOfflineHeaderEnabled || this.hasOfflineHandler)) {
          const header = origRequest.headers.get(Constants.USE_CACHED_RESPONSE_WHEN_OFFLINE);

          if (header) {
            // save the client associated with the request so it can be looked up when we call back
            // into the fetch handler from OPT response proxy
            this.saveRequestInfo(origRequest, { client });

            // use the default CacheIfOfflineStrategy to handle the request
            return Utils.getResource('persist/defaultResponseProxy')
              .then((DefaultResponseProxy) => {
                const options = OptCacheStrategiesFactory.getCacheStrategy(this.config, origRequest);
                return DefaultResponseProxy.getResponseProxy(options).processRequest(origRequest)
                  .finally(() => {
                    // delete the client associated with the request
                    this.deleteRequestInfo(origRequest);
                  });
              });
          }
        }

        // delegate to the offlineHandler to handle the request
        if (!skipOfflineHandler && this.hasOfflineHandler) {
          let requestInfo = this.getRequestInfo(origRequest);

          if (!requestInfo) {
            const offlineHandlerStack = this.getOfflineHandlers();
            requestInfo = {
              client,
              offlineHandlerStack,
            };

            // save the client associated with the request so it can be looked up when the offline handler calls
            // back into the fetch handler
            this.saveRequestInfo(origRequest, requestInfo);
          }

          const offlineHandler = requestInfo.offlineHandlerStack.pop();

          if (offlineHandler) {
            // hand off to the offline handler
            return offlineHandler.handleRequest(origRequest, this.scope, client).finally(() => {
              // delete the client associated with the request
              this.deleteRequestInfo(origRequest);
            });
          }
        }

        let origResponse;
        let origError;
        let modifiedRequest;
        return this.cloneRequest(origRequest)
          .then((request) => {
            // if the request is null, that means the clone failed so simply call fetch on the original request
            if (!request) {
              return this.fetch(origRequest);
            }

            return this.handleRequestHook(request, client)
              .then((r2) => {
                modifiedRequest = r2;
                return this.fetch(r2);
              })
              .then((response) => {
                origResponse = response;
                return this.handleResponseHook(response, origRequest, modifiedRequest, client);
              })
              .catch((err) => {
                logger.error(err);
                origError = err;
                return this.handleErrorHook(err, origRequest, modifiedRequest, client);
              })
              .then((retry) => {
                if (retry) {
                  if (retryCount < MAX_RETRIES) {
                    // retry
                    logger.info('Retrying request', retryCount);
                    return this.handleRequest(origRequest, client, skipOfflineHandler, retryCount + 1);
                  }

                  logger.info('Max retry count reached.');
                }

                if (origResponse) {
                  return origResponse;
                }

                throw origError;
              });
          });
      });
    }

    /**
     * @param request the request to consider for dynamic caching
     * @returns {Promise<Response>} a promise to a response that was fetched and cached, or undefined, if response
     * was not fetched
     */
    fetchAndCache(request) {
      return Promise.resolve()
        .then(() => {
          if (this.cacheStrategy) {
            return this.cacheStrategy.fetchAndCache(request);
          }
          return undefined;
        })
        .catch((err) => {
          logger.error('fetchAndCache() failed', err);
        });
    }

    /**
     * @param url
     * @param mode determines whether any optimizations should be performed during cache lookup
     * @returns {Promise<void>} a promise to get a response from a cache, or undefined, if no matching response is found
     * in the cache. Cache lookup could be skipped altogether if cacheStrategy.shouldCheckCache() returns false
     */
    checkCache(url, mode) {
      return Promise.resolve()
        .then(() => {
          if (this.cacheStrategy) {
            return this.cacheStrategy.shouldCheckCache(url)
              .then((shouldCheckCache) => {
                if (shouldCheckCache) {
                  return this.cacheStrategy.checkCache(url, mode);
                }
                return undefined;
              });
          }
          return undefined;
        })
        .catch((err) => {
          logger.error('checkCache() failed', err);
        });
    }

    /**
     * @returns {Promise<void>} a promise to cache assets during ServiceWorker install event
     */
    cacheOnInstall() {
      return Promise.resolve()
        .then(() => {
          if (this.cacheStrategy) {
            return this.cacheStrategy.cacheOnInstall();
          }
          return undefined;
        })
        .catch((err) => {
          logger.error('cacheOnInstall() failed', err);
        });
    }

    /**
     * @returns {Promise<void>} a promise to perform per request caching operations
     */
    cacheOnRequest() {
      return Promise.resolve()
        .then(() => this.cacheStrategy.cacheOnRequest())
        .catch((err) => {
          logger.error('cacheOnRequest() failed', err);
        });
    }
  }
  return FetchHandler;
});

