'use strict';

define('vb/private/vx/v2/endpointExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'vbsw/private/constants',
], (
  BaseExtensionAdapter,
  SwConstants,
) => {
  /**
   * The extension adapter used to load extension from the extension manager using an endpoint URL
   */
  class EndpointExtensionAdapter extends BaseExtensionAdapter {
    initiateFetchManifest() {
      return Promise.resolve()
        .then(() => this.loadDigest(['runtime', 'requirejs-info', 'app-ui-info']))
        .then((manifest) => {
          this.log.info('Found', manifest.extensions.length, 'extension(s).');
          return manifest;
        });
    }

    /**
     * Load the specified types from the remote extension digest
     * @param {String[]} types
     * @returns {Promise<object>}
     */
    loadDigest(types) {
      const requestUrl = this.getUri().search(`type=${types.join()}`).href();
      this.log.info('Loading digest at', requestUrl, 'using extension manager v2.');

      return Promise.resolve()
        .then(() => {
          const request = new Request(requestUrl);
          // use offline toolkit to handle caching
          request.headers.set(SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE, 'true');

          return this.getFetchManifestPromise(request);
        })
        .then((results) => {
          const digest = (results && results.digest) || {};

          const manifest = {};
          if (types.includes('runtime')) {
            // Extension Manager v2 returns digest.runtime[], so convert to v1 manifest.extensions[]
            manifest.extensions = digest.runtime || [];
          }
          if (types.includes('requirejs-info')) {
            manifest.requirejsInfo = digest['requirejs-info'] || [];
          }
          if (types.includes('app-ui-info')) {
            manifest.appUiInfo = digest['app-ui-info'] || [];
          }
          if (types.includes('pwa-info')) {
            manifest.pwaInfo = digest['pwa-info'] || [];
          }

          return manifest;
        });
    }
  }

  return EndpointExtensionAdapter;
});

