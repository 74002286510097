'use strict';

define('vb/extensions/dynamic/helpers/dynamicUiHelper',[], () => {
  /**
   * Symbol used to access the delegate helper
   * @type {symbol}
   */
  const delegate = Symbol('delegate-helper');

  /**
   * This class represents the public contract between Dynamic UI and VB for loading
   * layout resources.
   */
  class DynamicUiHelper {
    /**
     * DynamicUiHelper constructor
     *
     * @param delegateHelper
     */
    constructor(delegateHelper) {
      this[delegate] = delegateHelper;
    }

    /**
     * Returns an object that contains all information required by services layer to locate a service.
     *
     * @returns {Object}
     */
    get vbContext() {
      return this[delegate].vbContext;
    }

    /**
     * Returns true if the layout is runnable on the server, e.g., a BOSS layout.
     *
     * @returns {boolean}
     */
    get isRunnableOnServer() {
      return this[delegate].isRunnableOnServer;
    }

    /**
     * Loads resources related to global field template.
     *
     * @returns {Promise<[]>}
     */
    getGlobalResources() {
      return this[delegate].getGlobalResources();
    }

    /**
     * Loads shared global field templates from base extensions.
     *
     * @returns {Promise<[]>} an array of GFT resources from all the base extensions
     */
    getSharedGlobalResources() {
      return this[delegate].getSharedGlobalResources();
    }

    /**
     * Loads layout related resources.
     *
     * @returns {Promise<[]>}
     */
    getLayoutResources() {
      return this[delegate].getLayoutResources();
    }

    /**
     * Return the metadata (openapi3 or data-description.json), and a model, if there is one.
     *
     * @returns {Promise<Object>}
     */
    getMetadata() {
      return this[delegate].getMetadata();
    }

    /**
     * Return an unique url for caching metadata.
     *
     * @returns {String}
     */
    toUrl() {
      return this[delegate].toUrl();
    }

    /**
     * Load modules defined outside of the layout, e.g. transform functions. It will adjust the path for the module
     * if defined in a V2 extension before loading.
     *
     * @param path path for the module
     * @returns {Promise}
     */
    getExternalModuleResource(path) {
      return this[delegate].getExternalModuleResource(path);
    }

    /**
     * Create a new instance of DynamicUiHelper for the given options.
     *
     * @param options options for the new helper
     * @returns {Promise<DynamicUiHelper>}
     */
    getHelper(options) {
      // wrap helper in DynamicUiHelper so we don't expose internal implementation
      return this[delegate].getHelper(options)
        .then((helper) => new DynamicUiHelper(helper));
    }
  }

  return DynamicUiHelper;
});

