'use strict';

define('vb/private/stateManagement/fragmentHolderBaseMixin',[
  'vb/private/constants',
  'vb/private/utils',
  'vb/components/oj-vb-fragment/loader', // load fragment CCA so it's automatically available
], (Constants, Utils) => {
  /**
   * Base mixin that has common functions that all fragment holder containers share. Whether the container is an
   * extension or the base.
   */
  const FragmentHolderBaseMixin = (superclass) => class extends superclass {
    constructor(...args) {
      super(...args);

      /**
       * instances of loaded fragments used by this container.
       * @type {Object}
       */
      this.fragments = {};

      /**
       * fragmentBridge that connects this container to the component. Usually set by the fragmentBridge itself
       * @type {null|FragmentBridge}
       */
      this.fragmentBridge = null;

      this.parentOJModuleNode = undefined;

      this.loadHtmlPromise = null; // Initialized in loadTemplate

      const parent = this.getParentOrBaseOfExtension();

      const parentAllowsSlots = (parent && typeof parent.getCapability === 'function')
        ? parent.getCapability(Constants.FragmentCapability.ALLOWS_SLOTS) : true;
      /**
       * by default fragment allows slots to be used as long as its not turned off by its parent (e.g., in layouts)
       * @type {{allowSlots: boolean}}
       */
      this.capabilities = {
        allowSlots: parentAllowsSlots === undefined ? true : parentAllowsSlots,
      };
    }

    getParentOrBaseOfExtension() {
      return this.parent;
    }

    /**
     * Returns a value that is appropriate to the option that is passed in.
     * @param {String} option
     * @returns {*}
     */
    // eslint-disable-next-line class-methods-use-this
    getCapability(option) {
      if (option === Constants.FragmentCapability.ALLOWS_SLOTS) {
        return this.capabilities.allowSlots;
      }
      return null;
    }

    /**
     * Return a promise to load the .html
     *
     * @returns {Promise} a promise that resolve with the html text
     */
    loadTemplate() {
      // Keep a reference of the loading promise so that multiple function can wait
      // on the same promise to be resolved.
      this.loadHtmlPromise = this.loadHtmlPromise || this.templateLoader(this.getResourcePath());

      return this.loadHtmlPromise;
    }

    /**
     * Retrieves a fragment instance for the fragment id. If a Fragment instance cannot be located this method
     * creates the Fragment instance first and then returns the fragment context. This is only called from the
     * FragmentBridge.
     * @param {string} id of the fragment
     * @param {string} name of the fragment (artifacts)
     * @param {object} params input from caller
     * @param {object} templates defined on fragment
     */
    createFragment(id, name, params, templates) {
      let fragment = this.fragments[id];
      // if name and/or params are provided then we definitely construct a fragment, otherwise return the fragment
      // that was previously created or undefined.
      if (!fragment && name) {
        return this.getFragmentClass().then((FragmentClazz) => {
          fragment = new (FragmentClazz)(id, this, undefined, name, params, templates);
          this.fragments[id] = fragment;
          return fragment;
        });
      }

      return Promise.resolve(fragment);
    }

    getFragment(id) {
      return this.fragments[id];
    }

    /**
     * Returns the class used to instantiate the fragment.
     * @returns {Promise<unknown>}
     */
    getFragmentClass() {
      return Promise.resolve(this.constructor.FragmentClass);
    }

    disposeFragments() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });

      this.fragments = {};
    }

    /**
     * deletes a fragment from the container.
     * @param id
     */
    deleteFragment(id) {
      delete this.fragments[id];
    }

    /**
     * Traverse a set of definitions (variables or constants) and build new parameters
     * If the input parameter is already in the map, overwrite the existing definition
     * @param  {String} defName Either 'variables' or 'constants'
     * @param  {Object} allParameters the map of all the parameters
     */
    _buildParameters(defName, allParameters) {
      const parameters = allParameters;
      const variabledefs = this.getAllDefinitionOf(defName);
      Object.keys(variabledefs).forEach((name) => {
        const def = variabledefs[name];
        if (def.input === Constants.VariablePropertyInput.FROM_CALLER
          || def.input === Constants.VariablePropertyInput.FROM_URL) {
          if (parameters[name]) {
            this.log.warn('Input parameter', name, 'is already defined. Using the definition in', defName);
          }
          const inputParameterValue = this.getInputParameterValue(name, def);
          parameters[name] = inputParameterValue;
          this.log.info('Input parameter', name, '[input=', def.input, '] value:', inputParameterValue);
        }
      });
    }

    /**
     * Build a map of all possible input parameters to be used in $parameters for the
     * vbBeforeEnter event
     * @return {Object} a map of parameters
     */
    buildAllParameters() {
      // Add variables input parameters
      const parameters = {};
      this._buildParameters('variables', parameters);
      // Add constants input parameters
      // If an input parameter is already defined as a variable, the constant is used.
      this._buildParameters('constants', parameters);
      return parameters;
    }

    /**
     * Checks whether the current container has any dirty data variables.
     * It will check its current scope, any embedded fragments, layouts
     * and their extensions if present.
     * @return {boolean} true if there is at least one dirty data variable found;
     *                   false otherwise
     */
    checkForDirtyData() {
      // verify if any contained fragments have dirty data
      const hasDirtyData = Object.values(this.fragments).find((fragment) => fragment.checkForDirtyData());
      return hasDirtyData ? true : super.checkForDirtyData();
    }

    /**
     * Re-sets the dirty data state of variables in the current container's scope
     * as well as fragments and layouts and their extensions.
     */
    resetDirtyData() {
      // reset all fragment scopes
      Object.values(this.fragments).forEach((fragment) => {
        fragment.resetDirtyData();
      });

      super.resetDirtyData();
    }

    /**
     * Sets the ParentOJModuleNode for the container
     */
    setParentOJModuleNode(parentNode) {
      this.parentOJModuleNode = parentNode;
    }

    dispose(fromFlow) {
      this.disposeFragments();
      super.dispose(fromFlow);
    }
  };

  return FragmentHolderBaseMixin;
});

