'use strict';

define('vb/private/action/codeChainRunner',['vb/private/action/baseChainRunner', 'vb/private/constants', 'vb/private/utils',
  'vb/binding/expression', 'vb/action/action', 'vb/action/builtin/assignVariablesAction',
  'vb/private/action/actionRunner', 'vb/private/action/actionChainUtils'],
(BaseChainRunner, Constants, Utils, Expression, Action, AssignVariablesAction,
  ActionRunner, ActionChainUtils) => {
  class CodeChainRunner extends BaseChainRunner {
    createExecutionContext(context = {}, callingContexts = {}) {
      const executionContext = super.createExecutionContext(context, callingContexts);

      // for JS chains, sanitize the execution context and remove shortcuts, so we don't expose unnecessary
      // information
      ['$chains', '$imports', '$listeners', '$constants', '$enums', '$functions', '$metadata', '$modules',
        '$variables'].forEach((scopeName) => {
        delete executionContext[scopeName];
      });

      // proxy context to handle direct assignments and guard against illegal assignments
      return CodeChainRunner.proxyContext(executionContext);
    }

    /**
     * Create an instance of code chain.
     *
     * @param ChainClass the class for the chain
     * @returns {*}
     *
     * @overrides BaseChainRunner.createChain
     */
    // eslint-disable-next-line class-methods-use-this
    createChain(ChainClass) {
      if (typeof ChainClass === 'function') {
        return new ChainClass();
      }

      throw new Error(`Failed to create code chain from ${ChainClass}.`);
    }

    /**
     * Load the JS source file for the chain located at the given chainPath.
     *
     * @param chainPath location of the source file for the chain
     * @returns {Promise}
     * @overrides BaseChainRunner.loadChainSource
     */
    // eslint-disable-next-line class-methods-use-this
    loadChainSource(chainPath) {
      return Utils.getRuntimeEnvironment().then((re) => re.getModuleResource(chainPath));
    }

    /**
     * Since the result of a code chain is not an outcome, this method wraps it in a success outcome.
     *
     * @param result to be wrapped in a success outcome
     * @returns {{result: *, name: string}}
     * @overrides BaseChainRunner.coerceResultToOutcome
     */
    // eslint-disable-next-line class-methods-use-this
    coerceResultToOutcome(result) {
      return Action.createSuccessOutcome(result);
    }

    /**
     * For JS based action chain, fire a notification event to report the error by default.
     *
     * @param error the error to be reported
     * @param context the context necessary to run fireNotificationEventAction
     * @param rethrowError if true, rethrow the error instead of firing a notification event
     * @returns {Promise}
     *
     * @overrides BaseChainRunner.handleError
     */
    handleError(error, context, rethrowError) {
      if (!rethrowError) {
        // ignore the error until we implement a way for the application to register a global error handler
        return undefined;
      }

      // otherwise, rethrow the error
      throw error;
    }

    /**
     * Since a JS chain is not loaded via a fetch call, it cannot trigger a ResourceChangedEvent via
     * the ResourceChangedPlugin if application has been updated so we need to explicitly trigger
     * the ResourceChangedEvent by making a fetch call by returning true here.
     *
     * @returns {boolean}
     * @overrides BaseChainRunner.shouldTriggerResourceChangedEvent
     */
    // eslint-disable-next-line class-methods-use-this
    shouldTriggerResourceChangedEvent() {
      return true;
    }

    /**
     * Wrap executionContext in a Proxy that prevents direct modification of scopes. In addition,
     * it translates direct variable assignment into assignVariablesAction.
     *
     * Use getWritableExecutionContext to get a non-proxied version of the executionContext.
     *
     * @param executionContext the execution context to wrap
     * @returns {*}
     */
    static proxyContext(executionContext) {
      const scopeProxies = {};
      const internalContext = ActionChainUtils.getInternalContext(executionContext);

      // save the un-proxied version in the internal context so it can be easily retrieved when needed
      internalContext.writableContext = executionContext;

      return new Proxy(executionContext, {
        get(scopes, scopeName) {
          const scope = scopes[scopeName];

          // can't proxy undefined object
          if (!scope) {
            return undefined;
          }

          // don't proxy the internal context since it's only accessible internally
          if (scope === internalContext) {
            return scope;
          }

          // don't proxy $translation
          if (scopeName === '$translations') {
            return scope;
          }

          scopeProxies[scopeName] = scopeProxies[scopeName]
            || CodeChainRunner.proxyScope(scope, scopeName, executionContext);

          return scopeProxies[scopeName];
        },
        set(scopes, scopeName) {
          throw new Error(`Cannot write into ${String(scopeName)}`);
        },
      });
    }

    /**
     * Wrap the given scope object in a proxy.
     *
     * @param scope the scope to proxy
     * @param scopeName name of the scope, e.g., $application, $page
     * @param executionContext execution context
     * @returns {*}
     */
    static proxyScope(scope, scopeName, executionContext) {
      const nsProxies = {};

      // look up variable type
      const typeResolver = (varName) => {
        let actualVarName;
        if (Utils.isExtendedType(scope.variables[varName])) {
          actualVarName = `${varName}${Constants.BuiltinVariableName.VALUE}`;
        } else {
          actualVarName = varName;
        }

        const variable = scope.getVariable(actualVarName, Constants.VariableNamespace.VARIABLES);
        if (variable) {
          // for instance factory type, return a generic type definition for the constructorParams
          // which will direct the assignment proxy to use assignVariablesAction for any updates
          if (variable.typeClassification === Constants.VariableClassification.INSTANCE_FACTORY) {
            return { constructorParams: ['any'] };
          }
          return variable.getType();
        }
        return undefined;
      };

      return new Proxy(scope, {
        get(namespaces, nsName) {
          let nsProxy = nsProxies[nsName];

          if (!nsProxy) {
            const namespace = namespaces[nsName];

            if (nsName === Constants.VariableNamespace.VARIABLES) {
              nsProxy = CodeChainRunner.proxyVariableAssignment(namespace,
                `${String(scopeName)}.variables`, typeResolver, executionContext, true);
            } else if (Utils.isObjectOrArray(namespace) && Utils.isPrototypeOfObject(namespace)) {
              nsProxy = CodeChainRunner.preventWritesToObject(namespace);
            } else {
              return namespace;
            }

            nsProxies[nsName] = nsProxy;
          }

          return nsProxy;
        },
        set(namespaces, nsName) {
          throw new Error(`Cannot write into ${String(scopeName)}.${String(nsName)}`);
        },
      });
    }

    /**
     * Run an assignVariablesAction synchronously.
     *
     * @param executionContext execution context
     * @param params parameters to the assignmentVariableAction
     * @returns {*}
     */
    static runAssignVariableActionSync(executionContext, params) {
      return ActionRunner.runSync(AssignVariablesAction, 'vb/action/builtin/assignVariablesAction',
        executionContext, params, { alias: 'Actions.assignVariable' });
    }

    /**
     * Get the latest value for the given expression.
     *
     * @param expression expression to evaluate
     * @param executionContext context used for evaluation
     */
    static getFreshTarget(expression, executionContext) {
      return Expression.createFromString(expression,
        ActionChainUtils.getWritableExecutionContext(executionContext), false)();
    }

    /**
     * Proxy the given object that will translate direct assignment into an assignVariablesAction.
     *
     * @param obj object to proxy
     * @param expression left-hand side of the assignment
     * @param typeResolver function used to resolve the type defintion for a property
     * @param executionContext context used to run assignVariablesAction
     * @param cacheProxy if true, cache proxies created for any sub-properties
     * @returns {boolean|*}
     */
    static proxyVariableAssignment(obj, expression, typeResolver, executionContext, cacheProxy) {
      const cachedProxies = {};

      // generate a new expression by appending the given propName to the existing expression
      const newExpr = (propName) => (Number.isNaN(Number(propName))
        ? `${expression}['${propName}']` : `${expression}[${propName}]`);

      const proxy = new Proxy(obj, {
        get(target, propName) {
          let propProxy = cacheProxy ? cachedProxies[propName] : null;

          if (!propProxy) {
            // get the latest value in case the proxy has become stale
            const freshTarget = CodeChainRunner.getFreshTarget(expression, executionContext);

            if (propName === Constants.CHAIN_PROXY_TARGET) {
              return freshTarget;
            }

            const propValue = freshTarget[propName];

            if (typeof propValue === 'function') {
              return Array.isArray(target) ? CodeChainRunner.proxyArrayOperation(freshTarget, proxy,
                expression, propName, executionContext) : propValue;
            }

            if ((Utils.isObject(propValue) && Utils.isPrototypeOfObject(propValue))
              || Array.isArray(propValue) || Utils.isExtendedType(propValue)) {
              const type = typeResolver(propName);

              // don't proxy further if there's no type for this property because it's not
              // part of the VB variable definition
              if (!type) {
                return propValue;
              }

              // get the type definition for a property
              const propTypeResolver = (pn) => {
                if (Utils.isWildcardType(type)) {
                  return 'any';
                }
                if (Utils.isArrayType(type)) {
                  return Utils.getArrayRowType(type);
                }
                if (Utils.isObjectType(type)) {
                  return type[pn];
                }
                return undefined;
              };

              // TODO: This proxy will not cache proxies created for any sub-properties to avoid dealing
              // with stale proxies which can happen due to array operations such as splice and shift.
              propProxy = CodeChainRunner.proxyVariableAssignment(propValue, newExpr(propName),
                propTypeResolver, executionContext);
            } else {
              return propValue;
            }

            if (cacheProxy) {
              cachedProxies[propName] = propProxy;
            }
          }

          return propProxy;
        },
        ownKeys(target) {
          return Reflect.ownKeys(CodeChainRunner.getFreshTarget(expression, executionContext));
        },
        getOwnPropertyDescriptor(target, prop) {
          return Reflect.getOwnPropertyDescriptor(CodeChainRunner.getFreshTarget(expression, executionContext), prop);
        },
        set(target, propName, value) {
          const type = typeResolver(propName);

          if (type) {
            const params = {
              variable: newExpr(propName),
              value,
              auto: 'always',
              reset: 'empty', // default to empty to simulate the behavior of direct assignment
            };

            // disallow assigning an object to an array
            // Note: This is actually allowed by assignVariablesAction which interprets it as a push but
            // it is just weird when it comes to direct assignment.
            if (Array.isArray(target[propName]) && !Array.isArray(value)) {
              throw new Error(`Cannot assign non-array to an array property, ${String(propName)}`);
            }

            // run the assignVariablesAction in synchronous mode
            CodeChainRunner.runAssignVariableActionSync(executionContext, params);
          } else {
            // directly assign the value if there is no type info because it's not part of the variable
            // definition
            // eslint-disable-next-line no-param-reassign
            target[propName] = value;
          }

          return true;
        },
      });

      return proxy;
    }

    /**
     * Translate an array operation into an assignVariablesAction.
     *
     * @param origArray original unproxied array
     * @param proxiedArray proxied array
     * @param expression expression to be used in the assignVariablesAction.
     * @param operation name of the array operation
     * @param executionContext execution context for the action
     * @returns {function(...[*]=)}
     */
    static proxyArrayOperation(origArray, proxiedArray, expression, operation, executionContext) {
      return (...args) => {
        const params = {
          variable: expression,
          value: args,
          auto: 'always',
          reset: 'none',
        };

        let clonedArray;
        let result;

        switch (operation) {
          case 'push':
            CodeChainRunner.runAssignVariableActionSync(executionContext, params);
            return proxiedArray.length;
          case 'fill':
          case 'pop':
          case 'reverse':
          case 'shift':
          case 'sort':
          case 'splice':
          case 'unshift':
            clonedArray = [...origArray];
            result = clonedArray[operation](...args);

            // @ts-ignore
            params.value = clonedArray;
            params.reset = 'empty';
            CodeChainRunner.runAssignVariableActionSync(executionContext, params);

            if (operation === 'fill' || operation === 'reverse' || operation === 'sort') {
              return proxiedArray;
            }
            return result;
          default:
            return origArray[operation](...args);
        }
      };
    }

    /**
     * Proxy the given object to prevent any writes.
     *
     * @param obj object to proxy
     * @returns {*}
     */
    static preventWritesToObject(obj) {
      return new Proxy(obj, {
        get(target, propName) {
          const propValue = target[propName];

          const config = Object.getOwnPropertyDescriptor(target, propName);
          if (config && config.configurable === false && config.writable === false) {
            return propValue;
          }

          if (Utils.isObjectOrArray(propValue) && Utils.isPrototypeOfObject(propValue)) {
            return CodeChainRunner.preventWritesToObject(propValue);
          }

          return propValue;
        },
        set(target, propName) {
          throw new Error(`Cannot write into ${String(propName)}`);
        },
      });
    }
  }

  return CodeChainRunner;
});

